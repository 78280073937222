"use strict";

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.findAll = findAll;
exports.update = update;

var _request = _interopRequireDefault(require("@/utils/request"));

function findAll(params) {
  return (0, _request.default)({
    url: "/order",
    method: "get",
    params: params
  });
}

function create(data) {
  return (0, _request.default)({
    url: "/order",
    method: "post",
    data: data
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: "/order/" + id,
    method: "patch",
    data: data
  });
}