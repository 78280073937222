"use strict";

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.workSubmitApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var workSubmitApi = {
  create: function create(data) {
    return (0, _request.default)({
      url: '/work-submit',
      method: 'post',
      data: data
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/work-submit/".concat(id),
      method: 'patch',
      data: data
    });
  },
  checkRecord: function checkRecord(params) {
    return (0, _request.default)({
      url: "/work-submit/own",
      params: params
    });
  }
};
exports.workSubmitApi = workSubmitApi;