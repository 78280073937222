"use strict";

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.imeiRequestApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var imeiRequestApi = {
  checkReceive: function checkReceive(data) {
    return (0, _request.default)({
      url: "/imei-request/check",
      method: "post",
      data: data
    });
  },
  findRequestImeis: function findRequestImeis(params) {
    return (0, _request.default)({
      url: "/imei-request/imeis",
      method: "get",
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/imei-request",
      method: "post",
      data: data
    });
  },
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/imei-request",
      params: params
    });
  },
  findDetail: function findDetail(id, params) {
    return (0, _request.default)({
      url: "/imei-request/".concat(id, "/detail"),
      params: params
    });
  }
};
exports.imeiRequestApi = imeiRequestApi;