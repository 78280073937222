"use strict";

var _interopRequireWildcard = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/objectSpread2.js"));

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

var device = _interopRequireWildcard(require("@/api/device"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Service",
  props: {},
  data: function data() {
    return {
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 2,
        masked: false
      },
      deviceModel: {
        name: ""
      },
      search: "",
      dialogVisible: false,
      dialogStatus: "edit",
      arrModel: [],
      loadingButton: false,
      typeId: null,
      rules: {
        name: {
          required: true,
          message: "Service name is required",
          trigger: "submit"
        }
      }
    };
  },
  computed: {
    deviceModelTemp: function deviceModelTemp() {
      var _this = this;

      var arr = this.arrModel.filter(function (item) {
        return item.name.toLowerCase().includes(_this.search.toLowerCase());
      });
      return arr;
    }
  },
  mounted: function mounted() {},
  methods: {
    getData: function getData(typeId) {
      var _this2 = this;

      this.typeId = typeId;
      device.getAllModel(typeId).then(function (res) {
        _this2.arrModel = res.data;

        _this2.$emit("load:done");
      });
    },
    handleDelete: function handleDelete() {
      var _this3 = this;

      this.loadingButton = true;
      this.$confirm("This deviceModel will be delete. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(function () {
        device.deleteModel(_this3.deviceModel.id).then(function (res) {
          _this3.getData(_this3.typeId);

          _this3.$message({
            type: "success",
            message: res.message
          });
        });
      }).finally(function () {
        _this3.loadingButton = false;
        _this3.dialogVisible = false;
      });
    },
    formatCurrency: function formatCurrency(n) {
      return format.formatCurrency(n);
    },
    editData: function editData() {
      var _this4 = this;

      this.loadingButton = true;
      var data = {
        name: this.deviceModel.name
      };
      var modelId = this.deviceModel.id;
      device.updateModel(modelId, data).then(function () {
        _this4.$message({
          message: "Update device model successfully!",
          type: "success"
        });

        _this4.dialogVisible = false;

        _this4.getData(_this4.typeId);
      }).finally(function () {
        _this4.loadingButton = false;
      });
    },
    createData: function createData() {
      var _this5 = this;

      this.loadingButton = true;
      var data = {
        typeId: this.typeId,
        name: this.deviceModel.name
      };
      device.addModel(data).then(function (res) {
        _this5.$message({
          message: "Add device model successfully!",
          type: "success"
        });

        _this5.dialogVisible = false;

        _this5.arrModel.push(res.data);
      }).finally(function () {
        _this5.loadingButton = false;
      });
    },
    handleEdit: function handleEdit(item, index) {
      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.deviceModel = (0, _objectSpread2.default)({}, item);
    },
    handleAdd: function handleAdd() {
      this.deviceModel = {
        name: ""
      };
      this.dialogStatus = "add";
      this.dialogVisible = true;
    }
  }
};
exports.default = _default;