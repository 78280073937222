"use strict";

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.orderType = exports.orderStatusColor = exports.orderStatus = void 0;

var _defineProperty2 = _interopRequireDefault(require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _orderStatusColor;

var orderType = {
  ASSURANCE_WIRELESS: "ASSURANCE WIRELESS",
  SAFELINK_WIRELESS: "SAFELINK WIRELESS",
  MAXSIP_WIRELESS: "MAXSIP WIRELESS"
};
exports.orderType = orderType;
var orderStatus = {
  New: "NEW",
  Denied: "DENIED",
  Approved: "APPROVED",
  Ordered: "ORDERED",
  Shipped: "SHIPPED"
};
exports.orderStatus = orderStatus;
var orderStatusColor = (_orderStatusColor = {}, (0, _defineProperty2.default)(_orderStatusColor, orderStatus.New, {
  type: "",
  effect: "plain"
}), (0, _defineProperty2.default)(_orderStatusColor, orderStatus.Ordered, {
  type: "success",
  effect: "plain"
}), (0, _defineProperty2.default)(_orderStatusColor, orderStatus.Denied, {
  type: "danger",
  effect: "dark"
}), (0, _defineProperty2.default)(_orderStatusColor, orderStatus.Approved, {
  type: "success",
  effect: "dark"
}), (0, _defineProperty2.default)(_orderStatusColor, orderStatus.Shipped, {
  type: "",
  effect: "dark"
}), _orderStatusColor);
exports.orderStatusColor = orderStatusColor;