"use strict";

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSaleReport = getSaleReport;
exports.getSaleReportAll = getSaleReportAll;
exports.getSaleReportAllExcel = getSaleReportAllExcel;
exports.getSaleReportChain = getSaleReportChain;
exports.getSaleReportExcel = getSaleReportExcel;
exports.importSaleHistory = importSaleHistory;
exports.importSaleReport = importSaleReport;
exports.inventoryAgency = inventoryAgency;
exports.inventoryAgencyChild = inventoryAgencyChild;
exports.inventoryAgencyDetail = inventoryAgencyDetail;
exports.inventoryAllLevel = inventoryAllLevel;
exports.inventoryGroup = inventoryGroup;

var _request = _interopRequireDefault(require("@/utils/request"));

function inventoryAgency(data) {
  return (0, _request.default)({
    url: '/report/inventory/agency/parent',
    method: 'get',
    params: data
  });
}

function inventoryAllLevel(data) {
  return (0, _request.default)({
    url: '/report/inventory/allLevel',
    method: 'get',
    params: data
  });
}

function inventoryAgencyChild(userId) {
  return (0, _request.default)({
    url: '/report/inventory/agency/child',
    method: 'get',
    params: {
      userId: userId
    }
  });
}

function inventoryAgencyDetail(data) {
  return (0, _request.default)({
    url: '/report/inventory/agency/detail',
    method: 'get',
    params: data
  });
}

function importSaleReport(data) {
  return (0, _request.default)({
    url: '/report/sale/import',
    method: 'post',
    data: data
  });
}

function getSaleReport(listQuery) {
  return (0, _request.default)({
    url: '/report/sale',
    method: 'get',
    params: listQuery
  });
}

function getSaleReportAll(listQuery) {
  return (0, _request.default)({
    url: '/report/sale/all',
    method: 'get',
    params: listQuery
  });
}

function inventoryGroup(listQuery) {
  return (0, _request.default)({
    url: '/report/inventory/group',
    method: 'get',
    params: listQuery
  });
}

function getSaleReportChain(listQuery) {
  return (0, _request.default)({
    url: '/report/sale/chain',
    method: 'get',
    params: listQuery
  });
}

function importSaleHistory(listQuery) {
  return (0, _request.default)({
    url: '/report/sale/import/history',
    params: listQuery
  });
}

function getSaleReportExcel(query) {
  return (0, _request.default)({
    url: '/report/sale/excel',
    method: 'get',
    params: query
  });
}

function getSaleReportAllExcel(query) {
  return (0, _request.default)({
    url: '/report/sale/all/excel',
    method: 'get',
    params: query
  });
}