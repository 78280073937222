var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          {
            staticClass: "filter-item btn",
            staticStyle: { "margin-right": "10px", "margin-top": "15px" },
          },
          [
            _c("el-input", {
              staticStyle: { width: "250px" },
              attrs: {
                "prefix-icon": "el-icon-search",
                placeholder: "Search order..",
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.getList($event)
                },
              },
              model: {
                value: _vm.listQuery.search,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "search", $$v)
                },
                expression: "listQuery.search",
              },
            }),
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-search" },
                on: {
                  click: function ($event) {
                    return _vm.getList()
                  },
                },
              },
              [_vm._v("Search")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Status")]),
            _vm._v(" "),
            _c("br"),
            _c(
              "el-select",
              {
                staticStyle: { width: "150px" },
                attrs: { "value-key": "", placeholder: "", clearable: "" },
                on: { change: _vm.getList },
                model: {
                  value: _vm.listQuery.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "status", $$v)
                  },
                  expression: "listQuery.status",
                },
              },
              _vm._l(Object.values(_vm.orderStatus), function (item) {
                return _c("el-option", {
                  key: item,
                  attrs: { label: item, value: item },
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm.isAdmin
          ? _c(
              "div",
              {
                staticClass: "filter-item",
                staticStyle: { "margin-left": "10px" },
              },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Agency")]),
                _vm._v(" "),
                _c("br"),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "200px" },
                    attrs: {
                      loading: _vm.agencyLoading,
                      "value-key": "",
                      placeholder: "",
                      clearable: "",
                      filterable: "",
                      remote: "",
                      "remote-method": _vm.searchAgency,
                    },
                    on: { change: _vm.getList },
                    model: {
                      value: _vm.listQuery.userId,
                      callback: function ($$v) {
                        _vm.$set(_vm.listQuery, "userId", $$v)
                      },
                      expression: "listQuery.userId",
                    },
                  },
                  _vm._l(_vm.agencies, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "technician-table",
          attrs: {
            data: _vm.arrData,
            border: "",
            "highlight-current-row": "",
            fit: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Info", prop: "name", width: "300px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "word-wrap": "break-word",
                          "word-break": "keep-all",
                        },
                      },
                      [
                        _c("b", [_vm._v("Company:")]),
                        _vm._v(" " + _vm._s(row.companyName) + " "),
                        _c("br"),
                        _c("b", [_vm._v("Name of Recipent:")]),
                        _vm._v(" " + _vm._s(row.name) + " "),
                        _c("br"),
                        _c("b", [_vm._v("Phone:")]),
                        _vm._v(" " + _vm._s(row.phone) + " "),
                        _c("br"),
                        _c("b", [_vm._v("Email:")]),
                        _vm._v(" " + _vm._s(row.email) + " "),
                        _c("br"),
                        _c("b", [_vm._v("Address: ")]),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "word-wrap": "break-word",
                              "word-break": "keep-all",
                            },
                          },
                          [_vm._v(_vm._s(row.address))]
                        ),
                        _c("br"),
                        _c("b", [_vm._v("City: ")]),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "word-wrap": "break-word",
                              "word-break": "keep-all",
                            },
                          },
                          [_vm._v(_vm._s(row.city))]
                        ),
                        _c("br"),
                        _c("b", [_vm._v("State: ")]),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "word-wrap": "break-word",
                              "word-break": "keep-all",
                            },
                          },
                          [_vm._v(_vm._s(row.state))]
                        ),
                        _c("br"),
                        _c("b", [_vm._v("Zip code: ")]),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "word-wrap": "break-word",
                              "word-break": "keep-all",
                            },
                          },
                          [_vm._v(_vm._s(row.zipCode))]
                        ),
                        _c("br"),
                        _c("b", [_vm._v("Order at: ")]),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "word-wrap": "break-word",
                              "word-break": "keep-all",
                            },
                          },
                          [_vm._v(_vm._s(row.created_at))]
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "type", label: "ETC Name", width: "200px" },
          }),
          _c("el-table-column", {
            attrs: { prop: "agency", label: "Agency", width: "200px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.user
                      ? _c(
                          "p",
                          {
                            staticStyle: {
                              "word-wrap": "break-word",
                              "word-break": "keep-all",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(row.user.name) +
                                " - " +
                                _vm._s(row.user.username) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "amount",
              label: "Ordered quantity",
              width: "200px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("p", [
                      _c("b", [_vm._v("Ordered quantity:")]),
                      _vm._v(" " + _vm._s(_vm.formatNumberVN(row.amount))),
                    ]),
                    row.status != _vm.orderStatus.New
                      ? _c("p", [
                          _c("b", [_vm._v("Approved quantity :")]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.formatNumberVN(row.realAmount)) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Status", width: "160px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.orderStatusColor[row.status].type,
                          size: "normal",
                          effect: _vm.orderStatusColor[row.status].effect,
                        },
                      },
                      [_vm._v(_vm._s(row.status))]
                    ),
                    row.status == _vm.orderStatus.Shipped
                      ? _c("div", { staticStyle: { "margin-top": "10px" } }, [
                          _c(
                            "div",
                            { staticStyle: { "text-align": "left" } },
                            [
                              _c("b", [_vm._v("Tracking number:")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._l(
                                row.trackingCode.split(","),
                                function (item, index) {
                                  return _c(
                                    "p",
                                    {
                                      key: index,
                                      staticStyle: {
                                        margin: "0",
                                        "white-space": "nowrap",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.trim()) + " ")]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { prop: "note", label: "Note" } }),
          _vm.isAdmin
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "Action",
                  width: "230",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          row.status == _vm.orderStatus.New
                            ? _c(
                                "div",
                                [
                                  _c("el-button", {
                                    attrs: {
                                      type: "primary",
                                      size: "small",
                                      icon: "el-icon-edit",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleUpdateOrder(row)
                                      },
                                    },
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small", type: "success" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleUpdateStatus(
                                            row,
                                            "approve"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Approve")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small", type: "danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDeny(row)
                                        },
                                      },
                                    },
                                    [_vm._v("Deny")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          row.status == _vm.orderStatus.Approved
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small", type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleOrdered(row)
                                        },
                                      },
                                    },
                                    [_vm._v("Ordered")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          row.status == _vm.orderStatus.Ordered
                            ? _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "success" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleUpdateStatus(
                                        row,
                                        "shipped"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Shipped")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3549238094
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            top: "15px",
            title: _vm.handleTitleDialog(),
            visible: _vm.dialogVisible,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.tempData,
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Company name",
                                prop: "companyName",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.tempData.companyName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tempData, "companyName", $$v)
                                  },
                                  expression: "tempData.companyName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Name of Recipent",
                                prop: "name",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.tempData.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tempData, "name", $$v)
                                  },
                                  expression: "tempData.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Phone", prop: "phone" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.tempData.phone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tempData, "phone", $$v)
                                  },
                                  expression: "tempData.phone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Email", prop: "email" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.tempData.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tempData, "email", $$v)
                                  },
                                  expression: "tempData.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Address", prop: "address" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.tempData.address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tempData, "address", $$v)
                                  },
                                  expression: "tempData.address",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "City", prop: "city" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.tempData.city,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tempData, "city", $$v)
                                  },
                                  expression: "tempData.city",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "State", prop: "state" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.tempData.state,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tempData, "state", $$v)
                                  },
                                  expression: "tempData.state",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Zip code", prop: "zipCode" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.tempData.zipCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tempData, "zipCode", $$v)
                                  },
                                  expression: "tempData.zipCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "ETC Name", prop: "type" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.tempData.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tempData, "type", $$v)
                                  },
                                  expression: "tempData.type",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Order quantity",
                                prop: "amount",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.tempData.amount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tempData, "amount", $$v)
                                  },
                                  expression: "tempData.amount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Note", prop: "note" } },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", rows: 3 },
                                model: {
                                  value: _vm.tempData.note,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tempData, "note", $$v)
                                  },
                                  expression: "tempData.note",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "edit"
                        ? _vm.editData()
                        : _vm.createData()
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("approve-modal", {
        ref: "approveModal",
        on: {
          "submit-ok": function ($event) {
            return _vm.getList()
          },
        },
      }),
      _c("tracking-modal", {
        ref: "trackingModal",
        on: {
          "submit-ok": function ($event) {
            return _vm.getList()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }