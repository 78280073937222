var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Sale report detail",
        visible: _vm.dialogVisible,
        width: "1200px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-button",
        {
          attrs: { type: "success", loading: _vm.loadingButtonExcel },
          on: { click: _vm.downloadReport },
        },
        [_vm._v("Download report")]
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%", "margin-top": "20px" },
          attrs: {
            data: _vm.tableData,
            border: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", { attrs: { type: "index", label: "#" } }),
          _c("el-table-column", {
            attrs: { label: "Master Agent", prop: "lv1Name" },
          }),
          _c("el-table-column", {
            attrs: { label: "Distributor", prop: "lv2Name" },
          }),
          _c("el-table-column", {
            attrs: { label: "Retailer", prop: "lv3Name" },
          }),
          _c("el-table-column", {
            attrs: { label: "Employee", prop: "lv4Name" },
          }),
          _c("el-table-column", {
            attrs: { label: "Seller", prop: "sellerName" },
          }),
          _c("el-table-column", {
            attrs: { label: "Enrollment number", prop: "enrollmentNumber" },
          }),
          _c("el-table-column", {
            attrs: { label: "Active Time", prop: "activeTime" },
          }),
          _c("el-table-column", {
            attrs: { label: "First call made", prop: "firstCallMade" },
          }),
          _c("el-table-column", {
            attrs: { label: "First call date", prop: "firstCallDate" },
          }),
          _c("el-table-column", { attrs: { label: "ESN", prop: "esn" } }),
          _c("el-table-column", { attrs: { label: "Emei", prop: "imei" } }),
          _c("el-table-column", {
            attrs: { label: "Device Type", prop: "deviceType" },
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              label: "Found in inventory",
              prop: "foundInInventory",
            },
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: function ($event) {
            return _vm.getTableData()
          },
        },
      }),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }