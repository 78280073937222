"use strict";

var _interopRequireWildcard = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/objectSpread2.js"));

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

require("core-js/modules/es.function.name.js");

var device = _interopRequireWildcard(require("@/api/device"));

var _Model = _interopRequireDefault(require("./components/Model"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Model: _Model.default
  },
  data: function data() {
    return {
      deviceType: {
        name: ""
      },
      arrDeviceType: [],
      loadingGetData: false,
      loadingButton: false,
      dialogServiceGroupVisible: false,
      dialogServiceGroupStatus: "add",
      rulesServiceGroup: {
        name: {
          required: true,
          trigger: "submit",
          message: "Device type name is required"
        }
      },
      arrServiceGroup: [],
      searchType: ""
    };
  },
  computed: {
    arrTypeTemp: function arrTypeTemp() {
      var _this = this;

      var arr = this.arrDeviceType.filter(function (type) {
        return type.name.toLowerCase().includes(_this.searchType.toLowerCase());
      });
      return arr;
    }
  },
  mounted: function mounted() {
    this.getTypeAll();
  },
  methods: {
    handleAvatarSuccess: function handleAvatarSuccess(res) {
      this.image = res.data.path;
    },
    remove: function remove(id) {
      var _this2 = this;

      this.$confirm("This device type will be delete. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(function () {
        device.deleteType(id).then(function () {
          _this2.$message({
            message: "Remove service group successfully!",
            type: "success"
          });

          _this2.getTypeAll();
        });
      });
    },
    handleEditDeviceType: function handleEditDeviceType(typeId) {
      var _this3 = this;

      this.dialogServiceGroupVisible = true;
      this.dialogServiceGroupStatus = "edit";
      this.$nextTick(function () {
        _this3.$refs["formServiceGroup"].clearValidate();
      });
      device.getType(typeId).then(function (res) {
        _this3.deviceType = res.data;
      });
    },
    handleAdd: function handleAdd() {
      var _this4 = this;

      this.deviceType = {
        name: ""
      };
      this.dialogServiceGroupStatus = "add";
      this.dialogServiceGroupVisible = true;
      this.$nextTick(function () {
        _this4.$refs["formServiceGroup"].clearValidate();
      });
    },
    createDeviceType: function createDeviceType() {
      var _this5 = this;

      this.loadingButton = true;
      this.$refs["formServiceGroup"].validate(function (valid) {
        if (valid) {
          device.addTye(_this5.deviceType).then(function (res) {
            _this5.dialogServiceGroupVisible = false;

            _this5.$message({
              message: "Add device type successfully!",
              type: "success"
            });

            _this5.arrDeviceType.push(res.data);

            _this5.$nextTick(function () {
              _this5.$refs["parent-".concat(_this5.arrDeviceType.length - 1)][0].click();
            });
          }).finally(function () {
            _this5.loadingButton = false;
          });
        } else {
          _this5.loadingButton = false;
        }
      });
    },
    editDeviceType: function editDeviceType() {
      var _this6 = this;

      this.loadingButton = true;
      var typeId = this.deviceType.id;
      var data = {
        name: this.deviceType.name
      };
      device.updateType(typeId, data).then(function () {
        _this6.getTypeAll();
      }).finally(function () {
        _this6.loadingButton = true;
        _this6.dialogServiceGroupVisible = false;
      });
    },
    getTypeAll: function getTypeAll() {
      var _this7 = this;

      device.getAllType().then(function (res) {
        _this7.arrDeviceType = res.data;

        _this7.$nextTick().then(function () {
          _this7.$refs["parent-0"][0].click();
        });
      });
    },
    getDeviceModel: function getDeviceModel(item) {
      this.deviceType = (0, _objectSpread2.default)({}, item);
      this.loadingGetData = true;
      this.$refs["deviceModel"].getData(item.id);
    },
    setClickFirstGroup: function setClickFirstGroup() {
      this.$refs["item-service-group-0"][0].click();
    }
  }
};
exports.default = _default;