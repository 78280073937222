var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-alert", {
        attrs: {
          title: _vm.title[_vm.$route.query.status],
          type: "success",
          effect: "dark",
          closable: false,
        },
      }),
      _c(
        "el-link",
        { attrs: { type: "primary" }, on: { click: _vm.downloadReport } },
        [_vm._v("Download Report")]
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading",
            },
          ],
          staticClass: "report-detail-table",
          staticStyle: { "margin-top": "10px" },
          attrs: {
            data: _vm.tableData,
            border: "",
            "row-class-name": _vm.tableRowClassName,
          },
        },
        [
          _c("el-table-column", { attrs: { type: "index" } }),
          _c("el-table-column", {
            attrs: { label: "Master Agent", prop: "lv1Name" },
          }),
          _c("el-table-column", {
            attrs: { label: "Distributor", prop: "lv2Name" },
          }),
          _c("el-table-column", {
            attrs: { label: "Retailer", prop: "lv3Name" },
          }),
          _c("el-table-column", {
            attrs: { label: "Employee", prop: "lv4Name" },
          }),
          _c("el-table-column", { attrs: { label: "IMEI", prop: "imei" } }),
          _c("el-table-column", {
            attrs: { label: "Device type", prop: "deviceType" },
          }),
          _c("el-table-column", {
            attrs: { label: "Device model", prop: "deviceModel" },
          }),
          _c("el-table-column", {
            attrs: { label: "Uploaded date", prop: "created_at" },
          }),
          _c("el-table-column", {
            attrs: { label: "Asigned date", prop: "inventoryDate" },
          }),
          _c("el-table-column", { attrs: { label: "Status", prop: "status" } }),
          _c("el-table-column", {
            attrs: { label: "Aging Devices" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(_vm.fromNow(row.created_at)) + " "),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }