var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Imei:")]),
              _vm.totalRecord
                ? _c(
                    "span",
                    {
                      staticStyle: {
                        float: "right",
                        "background-color": "#dff0d8",
                      },
                    },
                    [
                      _vm._v(
                        "Total " + _vm._s(_vm.totalRecord) + " imei insert"
                      ),
                    ]
                  )
                : _vm._e(),
              _c("el-input", {
                staticStyle: { "margin-top": "10px" },
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 7, maxRows: 12 },
                  placeholder: "Please input",
                },
                model: {
                  value: _vm.objData.imei,
                  callback: function ($$v) {
                    _vm.$set(_vm.objData, "imei", $$v)
                  },
                  expression: "objData.imei",
                },
              }),
              _c("p", [_vm._v("Please enter imei by new line")]),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 24 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-top": "17px" },
                          attrs: {
                            type: "primary",
                            disabled: !_vm.totalRecord,
                            loading: _vm.loadingSubmit,
                          },
                          on: { click: _vm.handleReceive },
                        },
                        [_vm._v("Check IMEI")]
                      ),
                      _c("div", { staticStyle: { "margin-top": "12px" } }, [
                        _c("div", [
                          _c("span", { attrs: { for: "" } }, [
                            _vm._v("Valid imei: "),
                          ]),
                          _c("label", { staticStyle: { color: "#67C23A" } }, [
                            _vm._v(_vm._s(_vm.successImeis.length)),
                          ]),
                        ]),
                        _c("div", { staticStyle: { "margin-top": "4px" } }, [
                          _c("span", [_vm._v("Invalid imei: ")]),
                          _c("label", { staticStyle: { color: "#F56C6C" } }, [
                            _vm._v(_vm._s(_vm.failImeis.length)),
                          ]),
                        ]),
                        _c("div", { staticStyle: { "margin-top": "4px" } }, [
                          _c("span", [_vm._v("Expired 90 days: ")]),
                          _c(
                            "label",
                            { staticStyle: { color: "rgb(230, 162, 60)" } },
                            [_vm._v(_vm._s(_vm.expiredImeis.length))]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.successImeis.length
        ? _c(
            "el-button",
            {
              staticStyle: { "margin-bottom": "12px" },
              attrs: {
                type: "danger",
                size: "small",
                loading: _vm.loadingRemove,
              },
              on: { click: _vm.handleRemove },
            },
            [_vm._v("Receive & remove valid imei list")]
          )
        : _vm._e(),
      _c(
        "el-table",
        { attrs: { data: _vm.data, border: "", fit: "" } },
        [
          _c("el-table-column", { attrs: { label: "No.", type: "index" } }),
          _c("el-table-column", { attrs: { label: "Imei", prop: "imei" } }),
          _c("el-table-column", {
            attrs: { label: "Device type", prop: "product.model.type.name" },
          }),
          _c("el-table-column", {
            attrs: { label: "Device model", prop: "product.model.name" },
          }),
          _c("el-table-column", {
            attrs: { label: "Removed" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.product
                      ? [
                          row.product.active
                            ? _c("label", { attrs: { for: "" } }, [
                                _vm._v("No"),
                              ])
                            : _c("label", { attrs: { for: "" } }, [
                                _vm._v("Yes"),
                              ]),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Status", sortable: "", prop: "status" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.status == _vm.ImeiRequestStatus.expired
                      ? _c(
                          "label",
                          {
                            staticStyle: { color: "rgb(230, 162, 60)" },
                            attrs: { for: "" },
                          },
                          [_vm._v(" Expired 90 days ")]
                        )
                      : row.status == _vm.ImeiRequestStatus.valid
                      ? _c("label", { staticStyle: { color: "#67C23A" } }, [
                          _vm._v("Valid"),
                        ])
                      : _c("label", { staticStyle: { color: "#F56C6C" } }, [
                          _vm._v("Invalid"),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { label: "Note", prop: "note" } }),
        ],
        1
      ),
      _c("h2", { staticStyle: { "margin-top": "24px" } }, [_vm._v("Filter")]),
      _c(
        "el-collapse",
        { staticStyle: { "margin-bottom": "12px" } },
        [
          _c(
            "el-collapse-item",
            { attrs: { title: "Search Received Imei" } },
            [_c("imei-by-agency")],
            1
          ),
          _c(
            "el-collapse-item",
            { attrs: { title: "Receive History" } },
            [_c("receive-imei-history", { ref: "history" })],
            1
          ),
        ],
        1
      ),
      _c("receive-imei-modal", {
        ref: "ReceiveImeiModal",
        on: { "submit:ok": _vm.handleSubmitOk },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }