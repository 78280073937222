var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c("agency", { ref: "agency" }),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: { click: _vm.doSearchGroup },
                },
                [_vm._v("Search")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("hr"),
      _vm.tableData.length
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingButton,
                  expression: "loadingButton",
                },
              ],
              staticStyle: { width: "100%", "margin-top": "20px" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "highlight-current-row": "",
                "show-summary": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "#", width: "50px" },
              }),
              _c("el-table-column", {
                attrs: { label: "Master Agent" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            _vm._s(row.lv1 ? row.lv1.split("|")[0] : "--")
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  929207081
                ),
              }),
              _vm.level >= 2
                ? _c("el-table-column", {
                    attrs: { label: "Distributor" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                _vm._s(row.lv2 ? row.lv2.split("|")[0] : "--")
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      803801321
                    ),
                  })
                : _vm._e(),
              _vm.level >= 3
                ? _c("el-table-column", {
                    attrs: { label: "Retailer" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                _vm._s(row.lv3 ? row.lv3.split("|")[0] : "--")
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3318360873
                    ),
                  })
                : _vm._e(),
              _vm.level >= 4
                ? _c("el-table-column", {
                    attrs: { label: "Employee" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                _vm._s(row.lv4 ? row.lv4.split("|")[0] : "--")
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      164167913
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { label: "Used", prop: "used" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  row.used
                                    ? _vm.$router.open({
                                        name: "inventory-agency-detail",
                                        query: {
                                          userId: _vm.userSelect(row),
                                          status: "USED",
                                        },
                                      })
                                    : false
                                },
                              },
                            },
                            [_vm._v(_vm._s(row.used))]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4103799368
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "Free", prop: "free" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  row.free
                                    ? _vm.$router.open({
                                        name: "inventory-agency-detail",
                                        query: {
                                          userId: _vm.userSelect(row),
                                          status: "FREE",
                                        },
                                      })
                                    : false
                                },
                              },
                            },
                            [_vm._v(_vm._s(row.free))]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2503977307
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "Removed", prop: "removed" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  row.removed
                                    ? _vm.$router.open({
                                        name: "inventory-agency-detail",
                                        query: {
                                          userId: _vm.userSelect(row),
                                          status: "REMOVED",
                                        },
                                      })
                                    : false
                                },
                              },
                            },
                            [_vm._v(_vm._s(row.removed))]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2731231053
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c("section", { staticStyle: { "margin-top": "12px" } }, [
        _c(
          "div",
          [
            _c("label", { attrs: { for: "" } }, [
              _vm._v("Download total Used: "),
            ]),
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "mini",
                  loading: _vm.loadingDownload,
                },
                on: {
                  click: function ($event) {
                    return _vm.downloadExcel("USED")
                  },
                },
              },
              [_vm._v("Download (" + _vm._s(_vm.summary.used) + ")")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-top": "4px" } },
          [
            _c("label", { attrs: { for: "" } }, [
              _vm._v("Download total Free: "),
            ]),
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "mini",
                  loading: _vm.loadingDownload,
                },
                on: {
                  click: function ($event) {
                    return _vm.downloadExcel("FREE")
                  },
                },
              },
              [_vm._v("Download (" + _vm._s(_vm.summary.free) + ")")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-top": "4px" } },
          [
            _c("label", { attrs: { for: "" } }, [
              _vm._v("Download total Removed: "),
            ]),
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "mini",
                  loading: _vm.loadingDownload,
                },
                on: {
                  click: function ($event) {
                    return _vm.downloadExcel("REMOVED")
                  },
                },
              },
              [_vm._v("Download (" + _vm._s(_vm.summary.removed) + ")")]
            ),
          ],
          1
        ),
      ]),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
          pageSizes: [10, 20, 30, 50, 100],
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.doSearchGroup,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }