"use strict";

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.object.to-string.js");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _report = require("@/api/report");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tableData: [],
      tableLoading: false,
      total: 0,
      listQuery: {
        page: 1,
        limit: 10
      }
    };
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      (0, _report.importSaleHistory)(this.listQuery).then(function (res) {
        _this.tableData = res.data.data;
        _this.total = res.data.total;
      }).finally(function () {
        _this.tableLoading = false;
      });
    }
  },
  created: function created() {
    this.getTableData();
  }
};
exports.default = _default;