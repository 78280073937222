var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Imei:")]),
              _vm.totalRecord
                ? _c(
                    "span",
                    {
                      staticStyle: {
                        float: "right",
                        "background-color": "#dff0d8",
                      },
                    },
                    [
                      _vm._v(
                        "Total " + _vm._s(_vm.totalRecord) + " imei insert"
                      ),
                    ]
                  )
                : _vm._e(),
              _c("el-input", {
                staticStyle: { "margin-top": "10px" },
                attrs: {
                  type: "textarea",
                  rows: 7,
                  resize: "none",
                  placeholder: "Please input",
                },
                model: {
                  value: _vm.objData.imei,
                  callback: function ($$v) {
                    _vm.$set(_vm.objData, "imei", $$v)
                  },
                  expression: "objData.imei",
                },
              }),
              _c("p", [_vm._v("Please enter imei by new line")]),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 24 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-top": "17px" },
                          attrs: {
                            type: "danger",
                            disabled: !_vm.totalRecord,
                            loading: _vm.loadingSubmit,
                          },
                          on: { click: _vm.handleRemoveImei },
                        },
                        [_vm._v("Remove IMEI")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-button",
        {
          attrs: { type: "success", loading: _vm.loadingButtonExcel },
          on: { click: _vm.downloadReport },
        },
        [_vm._v("Download Report")]
      ),
      _c("h3", [_vm._v("Report IMEI removed")]),
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("label", { attrs: { for: "" } }, [_vm._v(" Search ")]),
          _c("el-input", {
            staticStyle: { width: "200px" },
            attrs: { placeholder: "Find imei" },
            nativeOn: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.getTableData($event)
              },
            },
            model: {
              value: _vm.listQuery.search,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "search", $$v)
              },
              expression: "listQuery.search",
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading",
            },
          ],
          staticStyle: { width: "100%", "margin-top": "20px" },
          attrs: {
            data: _vm.tableData,
            border: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", { attrs: { type: "index", label: "#" } }),
          _c("el-table-column", { attrs: { label: "Emei", prop: "imei" } }),
          _c("el-table-column", {
            attrs: { label: "Device type", prop: "typeName" },
          }),
          _c("el-table-column", {
            attrs: { label: "Device model", prop: "modelName" },
          }),
          _c("el-table-column", { attrs: { label: "Cause", prop: "cause" } }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _c("WarningRemoveModal", {
        ref: "WarningRemoveModal",
        on: { ok: _vm.removeImei },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }