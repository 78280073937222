var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-input", {
        attrs: { placeholder: "Serch device model" },
        model: {
          value: _vm.search,
          callback: function ($$v) {
            _vm.search = $$v
          },
          expression: "search",
        },
      }),
      _c(
        "el-button",
        {
          staticClass: "btn-add-service",
          attrs: { type: "primary", icon: "el-icon-circle-plus" },
          on: { click: _vm.handleAdd },
        },
        [_vm._v("Add device model")]
      ),
      _c(
        "el-row",
        { staticClass: "item-box", attrs: { gutter: 20 } },
        [
          _c("el-col", [
            !_vm.deviceModelTemp.length
              ? _c("p", [_vm._v("No data...")])
              : _vm._e(),
          ]),
          _vm._l(_vm.deviceModelTemp, function (item, index) {
            return _c("el-col", { key: index, attrs: { span: 8 } }, [
              _c(
                "div",
                {
                  staticClass: "item",
                  on: {
                    click: function ($event) {
                      return _vm.handleEdit(item, index)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "info-box" }, [
                    _c("div", { staticClass: "info-item" }, [
                      _c("span", { staticClass: "value-info" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]),
                  ]),
                  _c("i", { staticClass: "el-icon-info button-info" }),
                ]
              ),
            ])
          }),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              _vm.dialogStatus == "edit"
                ? "Edit device model"
                : "Add device model",
            visible: _vm.dialogVisible,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
              model: {
                value: _vm.deviceModel,
                callback: function ($$v) {
                  _vm.deviceModel = $$v
                },
                expression: "deviceModel",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Name", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Type device model name" },
                    model: {
                      value: _vm.deviceModel.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.deviceModel, "name", $$v)
                      },
                      expression: "deviceModel.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm.dialogStatus == "edit"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: { click: _vm.handleDelete },
                    },
                    [_vm._v("Delete")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "edit"
                        ? _vm.editData()
                        : _vm.createData()
                    },
                  },
                },
                [_vm._v("Submit")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }