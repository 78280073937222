"use strict";

var _interopRequireWildcard = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/objectSpread2.js"));

require("core-js/modules/es.object.to-string.js");

var _index = _interopRequireDefault(require("@/components/Agency/index.vue"));

var _moment = _interopRequireDefault(require("moment"));

var report = _interopRequireWildcard(require("@/api/report"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _rmaNumber = require("@/api/rma-number.api");

var _imeiRequest = require("@/api/imei-request");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Agency: _index.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      listQuery: {
        page: 1,
        limit: 10
      },
      loadingSearch: false,
      tableData: [],
      total: 0,
      dateRange: [(0, _moment.default)().format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")],
      agency: {
        lv1: null,
        lv2: null,
        lv3: null,
        lv4: null
      },
      searchImei: "",
      level: null,
      isAll: false
    };
  },
  methods: {
    changeDate: function changeDate(val) {
      this.listQuery.dateFrom = val[0];
      this.listQuery.dateTo = val[1];
    },
    doSearch: function doSearch() {
      var _this = this;

      this.agency = this.$refs["agency"].getData();
      var userLevelIds = {
        level1Id: this.agency["lv1"] == "All" ? null : this.agency["lv1"],
        level2Id: this.agency["lv2"] == "All" ? null : this.agency["lv2"],
        level3Id: this.agency["lv3"] == "All" ? null : this.agency["lv3"],
        level4Id: this.agency["lv4"] == "All" ? null : this.agency["lv4"]
      };
      this.loadingSearch = true;

      _imeiRequest.imeiRequestApi.findRequestImeis((0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), userLevelIds), {}, {
        search: this.searchImei
      })).then(function (res) {
        _this.tableData = res.data.data;
        _this.total = res.data.total;
      }).finally(function () {
        _this.loadingSearch = false;
      });
    }
  }
};
exports.default = _default;