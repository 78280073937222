var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Search")]),
              _c("el-input", {
                attrs: { placeholder: "Search by RMA number" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.fetchData($event)
                  },
                },
                model: {
                  value: _vm.query.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "search", $$v)
                  },
                  expression: "query.search",
                },
              }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { transform: "translateY(4px)" },
              attrs: { type: "primary", size: "small", icon: "el-icon-upload" },
              on: { click: _vm.handleImport },
            },
            [_vm._v("Import RNA Number")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "RMA Number", prop: "rmaNumber" },
          }),
          _c("el-table-column", { attrs: { label: "Esn", prop: "esn" } }),
          _c("el-table-column", {
            attrs: { label: "Retailer Name", prop: "retailerName" },
          }),
          _c("el-table-column", {
            attrs: { label: "PO Number", prop: "poNumber" },
          }),
          _c("el-table-column", {
            attrs: { label: "Order Number", prop: "orderNumber" },
          }),
          _c("el-table-column", {
            attrs: { label: "Invoice Number", prop: "invoiceNumber" },
          }),
          _c("el-table-column", {
            attrs: { label: "Replacement Esn", prop: "replacementEsn" },
          }),
          _c("el-table-column", {
            attrs: { label: "RMA Eligible" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.isEligible
                      ? _c("span", [_vm._v(" YES ")])
                      : _c("span", [_vm._v("NO")]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("rma-number-import-modal", {
        ref: "RmaNumberImportModal",
        on: { "submit:ok": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }