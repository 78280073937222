var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { attrs: { gutter: 20 } },
    [
      _c(
        "el-col",
        { attrs: { xs: 12, span: 6 } },
        [
          _c("label", { attrs: { for: "" } }, [_vm._v("1. Master agent")]),
          _c(
            "el-select",
            {
              staticStyle: { "margin-top": "10px", width: "100%" },
              attrs: {
                filterable: "",
                placeholder: "Select",
                disabled: _vm.agencyLevel && _vm.agencyLevel >= 1,
              },
              on: { change: _vm.handleChangeLevel1 },
              model: {
                value: _vm.agency.lv1,
                callback: function ($$v) {
                  _vm.$set(_vm.agency, "lv1", $$v)
                },
                expression: "agency.lv1",
              },
            },
            [
              _c("el-option", { attrs: { label: "No select", value: null } }),
              _vm.visibleAll(1)
                ? _c("el-option", { attrs: { label: "All", value: "All" } })
                : _vm._e(),
              _vm._l(_vm.levels.lv1, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { xs: 12, span: 6 } },
        [
          _c("label", { attrs: { for: "" } }, [_vm._v("2. Distributor")]),
          _c(
            "el-select",
            {
              staticStyle: { "margin-top": "10px", width: "100%" },
              attrs: {
                disabled:
                  !_vm.agency.lv1 || (_vm.agencyLevel && _vm.agencyLevel >= 2),
                filterable: "",
                placeholder: "Select device type",
              },
              on: { change: _vm.handleChangeLevel2 },
              model: {
                value: _vm.agency.lv2,
                callback: function ($$v) {
                  _vm.$set(_vm.agency, "lv2", $$v)
                },
                expression: "agency.lv2",
              },
            },
            [
              _c("el-option", { attrs: { label: "No select", value: null } }),
              _vm.visibleAll(2)
                ? _c("el-option", { attrs: { label: "All", value: "All" } })
                : _vm._e(),
              _vm._l(_vm.filterLevel2, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { xs: 12, span: 6 } },
        [
          _c("label", { attrs: { for: "" } }, [_vm._v("3. Retailer")]),
          _c(
            "el-select",
            {
              staticStyle: { "margin-top": "10px", width: "100%" },
              attrs: {
                filterable: "",
                disabled:
                  !_vm.agency.lv2 || (_vm.agencyLevel && _vm.agencyLevel >= 3),
                placeholder: "Select device type",
              },
              on: { change: _vm.handleChangeLevel3 },
              model: {
                value: _vm.agency.lv3,
                callback: function ($$v) {
                  _vm.$set(_vm.agency, "lv3", $$v)
                },
                expression: "agency.lv3",
              },
            },
            [
              _c("el-option", { attrs: { label: "No select", value: null } }),
              _vm.visibleAll(3)
                ? _c("el-option", { attrs: { label: "All", value: "All" } })
                : _vm._e(),
              _vm._l(_vm.filterLevel3, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { xs: 12, span: 6 } },
        [
          _c("label", { attrs: { for: "" } }, [_vm._v("4. Employee")]),
          _c(
            "el-select",
            {
              staticStyle: { "margin-top": "10px", width: "100%" },
              attrs: {
                filterable: "",
                placeholder: "Select device type",
                disabled:
                  !_vm.agency.lv3 || (_vm.agencyLevel && _vm.agencyLevel >= 4),
              },
              model: {
                value: _vm.agency.lv4,
                callback: function ($$v) {
                  _vm.$set(_vm.agency, "lv4", $$v)
                },
                expression: "agency.lv4",
              },
            },
            [
              _c("el-option", { attrs: { label: "No select", value: null } }),
              _vm.visibleAll(4)
                ? _c("el-option", { attrs: { label: "All", value: "All" } })
                : _vm._e(),
              _vm._l(_vm.filterLevel4, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }