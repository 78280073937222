"use strict";

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

var _interopRequireWildcard = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.object.keys.js");

var report = _interopRequireWildcard(require("@/api/report"));

var _Detail = _interopRequireDefault(require("./components/Detail"));

var inventory = _interopRequireWildcard(require("@/api/inventory"));

var user = _interopRequireWildcard(require("@/api/user"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _moment = _interopRequireDefault(require("moment"));

var _Agency = _interopRequireDefault(require("@/components/Agency"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    ReportDetail: _Detail.default,
    Agency: _Agency.default
  },
  data: function data() {
    return {
      loadingButtonExcel: false,
      tableData: [],
      tableHeader: [],
      isLoading: false,
      dateRange: [(0, _moment.default)().format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")],
      agency: {
        lv1: null,
        lv2: null,
        lv3: null,
        lv4: null
      },
      level: null,
      levels: {
        lv1: [],
        lv2: [],
        lv3: [],
        lv4: []
      },
      isAll: false,
      total: 0,
      listQuery: {
        page: 1,
        limit: 10,
        userId: null,
        dateFrom: (0, _moment.default)().format("YYYY-MM-DD"),
        dateTo: (0, _moment.default)().format("YYYY-MM-DD")
      },
      loadingButton: false
    };
  },
  methods: {
    handleDetail: function handleDetail(row) {
      var _this = this;

      this.listQuery.userId = row["lv".concat(this.level)];
      this.$nextTick(function () {
        _this.$refs["report-detail"].getTableData();
      });
    },
    changeDate: function changeDate(val) {
      this.listQuery.dateFrom = val[0];
      this.listQuery.dateTo = val[1];
    },
    doSearch: function doSearch() {
      var _this2 = this;

      this.agency = this.$refs["agency"].getData();
      var levels = {
        lv1: this.agency.lv1,
        lv2: this.agency.lv2,
        lv3: this.agency.lv3,
        lv4: this.agency.lv4
      };
      var flag = false;
      Object.keys(levels).forEach(function (key, index) {
        if (levels[key]) {
          _this2.level = index + 1;
          flag = true;
        }
      });

      if (!flag) {
        return this.$message({
          type: "error",
          message: "Please select agent"
        });
      }

      this.loadingButton = true;
      this.listQuery.levels = levels;
      report.getSaleReportChain(this.listQuery).then(function (res) {
        _this2.tableData = res.data.data;
        _this2.total = res.data.total;

        if (!_this2.total) {
          _this2.$notify({
            type: "warning",
            message: "Not found"
          });
        }
      }).finally(function () {
        _this2.loadingButton = false;
      });
    }
  }
};
exports.default = _default;