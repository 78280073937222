"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.object.to-string.js");

var _user = require("@/api/user");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    token: {
      type: String
    },
    username: {
      type: String
    }
  },
  methods: {
    handleSubmit: function handleSubmit() {
      var _this = this;

      if (this.dataForm.password == "" || this.dataForm.rePassword == "") {
        return this.$message({
          type: "error",
          message: "Password is required"
        });
      }

      if (this.dataForm.password != this.dataForm.rePassword) {
        return this.$message({
          type: "error",
          message: "Two password not match"
        });
      }

      var data = {
        token: this.token,
        password: this.dataForm.password
      };
      this.loading = true;
      (0, _user.resetPassword)(data).then(function (res) {
        if (res.status) {
          _this.$emit("reset:success");

          _this.$message({
            type: "success",
            message: "Reset password successfully"
          });
        } else {
          _this.$message({
            type: "error",
            message: res.message
          });
        }
      }).finally(function () {
        _this.loading = false;
      });
    }
  },
  data: function data() {
    return {
      loading: false,
      dataForm: {
        username: this.username,
        password: "",
        rePassword: ""
      }
    };
  }
};
exports.default = _default;