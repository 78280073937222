"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exportReportInventoryDetail = void 0;

require("core-js/modules/es.array.map.js");

var _Export2Excel = require("@/vendor/Export2Excel");

var formatJson = function formatJson(filterVal, jsonData) {
  return jsonData.map(function (v) {
    return filterVal.map(function (j) {
      return v[j];
    });
  });
};

var exportReportInventoryDetail = function exportReportInventoryDetail(list) {
  var tHeader = ["Master Agent", "Distributor", "Retailer", "Employee", "IMEI", "Device type", "Device model", "Uploaded date", "Asigned date", "Status", "Aging Devices"];
  var filterVal = ["lv1Name", "lv2Name", "lv3Name", "lv4Name", "imei", "deviceType", "deviceModel", "created_at", "inventoryDate", "status", "agingDevices"];
  var data = formatJson(filterVal, list);
  (0, _Export2Excel.export_json_to_excel)({
    header: tHeader,
    data: data,
    filename: ""
  });
};

exports.exportReportInventoryDetail = exportReportInventoryDetail;