var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Imei:")]),
              _vm.totalRecord
                ? _c(
                    "span",
                    {
                      staticStyle: {
                        float: "right",
                        "background-color": "#dff0d8",
                      },
                    },
                    [
                      _vm._v(
                        "Total " + _vm._s(_vm.totalRecord) + " imei insert"
                      ),
                    ]
                  )
                : _vm._e(),
              _c("el-input", {
                staticStyle: { "margin-top": "10px" },
                attrs: {
                  type: "textarea",
                  rows: 7,
                  resize: "none",
                  placeholder: "Please input",
                },
                model: {
                  value: _vm.objData.imei,
                  callback: function ($$v) {
                    _vm.$set(_vm.objData, "imei", $$v)
                  },
                  expression: "objData.imei",
                },
              }),
              _c("p", [_vm._v("Please enter imei by new line")]),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Device type"),
                      ]),
                      _c(
                        "el-select",
                        {
                          staticStyle: { "margin-top": "10px" },
                          attrs: {
                            filterable: "",
                            placeholder: "Select device type",
                          },
                          model: {
                            value: _vm.typeId,
                            callback: function ($$v) {
                              _vm.typeId = $$v
                            },
                            expression: "typeId",
                          },
                        },
                        _vm._l(_vm.arrDevice, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Device model"),
                      ]),
                      _c(
                        "el-select",
                        {
                          staticStyle: { "margin-top": "10px" },
                          attrs: {
                            filterable: "",
                            disabled: !_vm.typeId,
                            placeholder: "Select device model",
                          },
                          model: {
                            value: _vm.objData.modelId,
                            callback: function ($$v) {
                              _vm.$set(_vm.objData, "modelId", $$v)
                            },
                            expression: "objData.modelId",
                          },
                        },
                        _vm._l(_vm.arrModel, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 24 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loadingSubmit,
                          },
                          on: { click: _vm.createData },
                        },
                        [_vm._v("Submit")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("h3", [_vm._v("Warehouse history")]),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Select date:")]),
              _c("el-date-picker", {
                attrs: {
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  type: "daterange",
                  "range-separator": "To",
                  "start-placeholder": "Start date",
                  "end-placeholder": "End date",
                },
                on: { change: _vm.changeDate },
                model: {
                  value: _vm.dateRange,
                  callback: function ($$v) {
                    _vm.dateRange = $$v
                  },
                  expression: "dateRange",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading",
            },
          ],
          staticStyle: { "margin-top": "15px" },
          attrs: { data: _vm.tableData, border: "", stripe: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: { label: "Batch code", prop: "batchCode" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("el-link", [_vm._v(_vm._s(row.batchCode))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Device type", prop: "deviceType" },
          }),
          _c("el-table-column", {
            attrs: { label: "Device model", prop: "deviceModel" },
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "text-center",
              label: "Total record",
              prop: "totalRecord",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "Date", prop: "created_at" },
          }),
          _c("el-table-column", {
            attrs: { label: "Action" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.getHistoryDetail(row.id)
                          },
                        },
                      },
                      [_vm._v("Detail")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getAllInventory,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }