var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _vm.statusForm == "login"
        ? _c(
            "el-form",
            {
              ref: "loginForm",
              staticClass: "login-form",
              attrs: {
                model: _vm.loginForm,
                rules: _vm.loginRules,
                autocomplete: "on",
                "label-position": "left",
              },
            },
            [
              _c("div", { staticClass: "title-container text-center" }, [
                _c("img", {
                  staticStyle: { filter: "brightness(5)" },
                  attrs: {
                    src: require("@/assets/images/logo.png"),
                    width: "200",
                    alt: "",
                  },
                }),
              ]),
              _c(
                "el-form-item",
                { attrs: { prop: "username" } },
                [
                  _c(
                    "span",
                    { staticClass: "svg-container" },
                    [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                    1
                  ),
                  _c("el-input", {
                    ref: "username",
                    attrs: {
                      placeholder: "Username",
                      name: "username",
                      type: "text",
                      tabindex: "1",
                      autocomplete: "on",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleLogin($event)
                      },
                    },
                    model: {
                      value: _vm.loginForm.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.loginForm, "username", $$v)
                      },
                      expression: "loginForm.username",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: "Caps lock is On",
                    placement: "right",
                    manual: "",
                  },
                  model: {
                    value: _vm.capsTooltip,
                    callback: function ($$v) {
                      _vm.capsTooltip = $$v
                    },
                    expression: "capsTooltip",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password" } },
                    [
                      _c(
                        "span",
                        { staticClass: "svg-container" },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "password" },
                          }),
                        ],
                        1
                      ),
                      _c("el-input", {
                        key: _vm.passwordType,
                        ref: "password",
                        attrs: {
                          type: _vm.passwordType,
                          placeholder: "Password",
                          name: "password",
                          tabindex: "2",
                          autocomplete: "on",
                        },
                        on: {
                          blur: function ($event) {
                            _vm.capsTooltip = false
                          },
                        },
                        nativeOn: {
                          keyup: [
                            function ($event) {
                              return _vm.checkCapslock($event)
                            },
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleLogin($event)
                            },
                          ],
                        },
                        model: {
                          value: _vm.loginForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "password", $$v)
                          },
                          expression: "loginForm.password",
                        },
                      }),
                      _c(
                        "span",
                        { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                        [
                          _c("svg-icon", {
                            attrs: {
                              "icon-class":
                                _vm.passwordType === "password"
                                  ? "eye"
                                  : "eye-open",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%", "margin-bottom": "30px" },
                  attrs: { loading: _vm.loading, type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleLogin($event)
                    },
                  },
                },
                [_vm._v("Login")]
              ),
            ],
            1
          )
        : _c("reset-password", {
            attrs: { token: _vm.resetToken, username: _vm.username },
            on: {
              "reset:success": _vm.resetSuccess,
              "update:token": function ($event) {
                _vm.resetToken = $event
              },
              "update:username": function ($event) {
                _vm.username = $event
              },
            },
          }),
      _c(
        "el-dialog",
        {
          attrs: { title: "Or connect with", visible: _vm.showDialog },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _vm._v(
            " Can not be simulated on local, so please combine you own business simulation! ! ! "
          ),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("social-sign"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }