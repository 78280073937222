"use strict";

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.blockUser = blockUser;
exports.checkResetPassword = checkResetPassword;
exports.getAllRootTree = getAllRootTree;
exports.getAllUser = getAllUser;
exports.getInfo = getInfo;
exports.getInfoById = getInfoById;
exports.getRootTree = getRootTree;
exports.login = login;
exports.logout = logout;
exports.register = register;
exports.requestResetPassword = requestResetPassword;
exports.resetPassword = resetPassword;
exports.resetPasswordDry = resetPasswordDry;
exports.unBlockUser = unBlockUser;
exports.update = update;
exports.updatePassword = updatePassword;

var _request = _interopRequireDefault(require("@/utils/request"));

function login(data) {
  return (0, _request.default)({
    url: '/user/login',
    method: 'post',
    data: data
  });
}

function checkResetPassword(token) {
  return (0, _request.default)({
    url: '/user/checkResetPassword',
    method: 'post',
    data: {
      token: token
    }
  });
}

function resetPasswordDry(data) {
  return (0, _request.default)({
    url: '/user/reset-password/dry',
    method: 'post',
    data: data
  });
}

function requestResetPassword(data) {
  return (0, _request.default)({
    url: '/user/reset-password',
    method: 'post',
    data: data
  });
}

function resetPassword(data) {
  return (0, _request.default)({
    url: '/user/smResetPassword',
    method: 'post',
    data: data
  });
}

function register(data) {
  return (0, _request.default)({
    url: '/user/register',
    method: 'post',
    data: data
  });
}

function blockUser(userId) {
  return (0, _request.default)({
    url: '/user/block',
    method: 'post',
    data: {
      userId: userId
    }
  });
}

function unBlockUser(userId) {
  return (0, _request.default)({
    url: '/user/unBlock',
    method: 'post',
    data: {
      userId: userId
    }
  });
}

function getAllUser(listQuery) {
  return (0, _request.default)({
    url: '/user/all',
    method: 'get',
    params: listQuery
  });
}

function getAllRootTree() {
  return (0, _request.default)({
    url: '/user/allRootTree'
  });
}

function getInfo() {
  return (0, _request.default)({
    url: '/user/info',
    method: 'get'
  });
}

function getInfoById(userId) {
  return (0, _request.default)({
    url: "/user/info/".concat(userId),
    method: 'get'
  });
}

function update(userId, data) {
  return (0, _request.default)({
    url: "/user/update/".concat(userId),
    method: 'post',
    data: data
  });
}

function updatePassword(data) {
  return (0, _request.default)({
    url: '/user/update/password',
    method: 'post',
    data: data
  });
}

function getRootTree(userId) {
  return (0, _request.default)({
    url: "/user/root/".concat(userId),
    method: 'get'
  });
}

function logout() {
  return (0, _request.default)({
    url: '/user/logout',
    method: 'post'
  });
}