"use strict";

var _interopRequireWildcard3 = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.array.join.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.json.stringify.js");

require("core-js/modules/es.array.map.js");

var device = _interopRequireWildcard3(require("@/api/device"));

var product = _interopRequireWildcard3(require("@/api/product"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      loadingSubmit: false,
      typeId: null,
      objData: {
        imei: null,
        modelId: null
      },
      dateRange: [(0, _moment.default)().format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")],
      totalRecord: 0,
      arrDevice: [],
      tableData: [],
      total: 0,
      listQuery: {
        page: 1,
        limit: 10,
        dateFrom: (0, _moment.default)().format("YYYY-MM-DD"),
        dateTo: (0, _moment.default)().format("YYYY-MM-DD")
      },
      tableLoading: false
    };
  },
  computed: {
    arrModel: function arrModel() {
      var _this = this;

      var arr;
      this.arrDevice.forEach(function (item) {
        if (item.id == _this.typeId) {
          arr = item.devices;
        }
      });
      return arr;
    }
  },
  created: function created() {
    this.getAllInventory();
    this.getAllDevice();

    if (localStorage.getItem("imeis")) {
      this.objData.imei = JSON.parse(localStorage.getItem("imeis")).join("\n");
    }
  },
  watch: {
    typeId: function typeId() {
      this.objData.modelId = null;
    },
    "objData.imei": function objDataImei(val) {
      var arr = val.split("\n");
      var res = arr.filter(function (item) {
        if (item) return item;
      });
      localStorage.setItem("imeis", JSON.stringify(arr));
      this.totalRecord = res.length;
    }
  },
  methods: {
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          return v[j];
        });
      });
    },
    getHistoryDetail: function getHistoryDetail(inventoryId) {
      var _this2 = this;

      var tHeader = ["Imei", "Status", "Device type", "Device model", "Master Agent", "Distributor", "Retailer", "Employee", "Date"];
      var filterVal = ["imei", "status", "typeName", "modelName", "lv1", "lv2", "lv3", "lv4", "date"];
      this.tableLoading = true;
      product.getHistory(inventoryId).then(function (res) {
        Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/vendor/Export2Excel"));
        }).then(function (excel) {
          var list = res.data;

          var data = _this2.formatJson(filterVal, list);

          excel.export_json_to_excel({
            header: tHeader,
            data: data,
            filename: ""
          });
          _this2.tableLoading = false;
        });
      }).finally(function () {
        _this2.tableLoading = false;
      });
    },
    changeDate: function changeDate(val) {
      this.listQuery.dateFrom = val[0];
      this.listQuery.dateTo = val[1];
      this.getAllInventory();
    },
    createData: function createData() {
      var _this3 = this;

      if (!this.objData.imei) {
        return this.$message({
          type: "error",
          message: "Imei is not empty"
        });
      }

      var arr = this.objData.imei.split("\n");

      if (!this.objData.modelId) {
        return this.$message({
          type: "error",
          message: "Please select device model"
        });
      }

      var res = arr.filter(function (item) {
        if (item) return item;
      });
      var data = {
        imei: res,
        modelId: this.objData.modelId
      };
      this.loadingSubmit = true;
      product.createProduct(data).then(function (res) {
        _this3.objData = {
          imei: "",
          modelId: null
        };
        localStorage.setItem("imeis", "");

        _this3.$message({
          type: "success",
          message: res.message
        });

        _this3.getAllInventory();
      }).finally(function () {
        _this3.loadingSubmit = false;
      });
    },
    getAllInventory: function getAllInventory() {
      var _this4 = this;

      this.tableLoading = true;
      product.getAllInventory(this.listQuery).then(function (res) {
        _this4.tableData = res.data.data;
        _this4.total = res.data.total;
      }).finally(function () {
        _this4.tableLoading = false;
      });
    },
    getAllDevice: function getAllDevice() {
      var _this5 = this;

      device.getAllDevice().then(function (res) {
        _this5.arrDevice = res.data;
      });
    }
  }
};
exports.default = _default;