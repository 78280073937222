"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.error.cause.js");

require("core-js/modules/es.object.to-string.js");

var _user = require("@/api/user");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    var _this = this;

    var validatePass = function validatePass(rule, value, callback) {
      if (value === "") {
        callback(new Error("Please input the password"));
      } else {
        if (_this.dataForm.reNewPass !== "") {
          _this.$refs.form.validateField("reNewPass");
        }

        callback();
      }
    };

    var validatePass2 = function validatePass2(rule, value, callback) {
      if (value === "") {
        callback(new Error("Please input the password again"));
      } else if (value !== _this.dataForm.newPass) {
        callback(new Error("Two inputs don't match!"));
      } else {
        callback();
      }
    };

    var validateOldPass = function validateOldPass(rule, value, callback) {
      if (value === "") {
        return callback(new Error("Old password is required"));
      }

      callback();
    };

    return {
      loadingButton: false,
      dataForm: {
        oldPass: "",
        newPass: "",
        reNewPass: ""
      },
      rules: {
        oldPass: [{
          required: true,
          validator: validateOldPass,
          trigger: "submit"
        }],
        newPass: [{
          required: true,
          validator: validatePass,
          trigger: "submit"
        }],
        reNewPass: [{
          required: true,
          validator: validatePass2,
          trigger: "submit"
        }]
      }
    };
  },
  methods: {
    submit: function submit() {
      var _this2 = this;

      var data = {
        oldPassword: this.dataForm.oldPass,
        newPassword: this.dataForm.newPass
      };
      this.loadingButton = true;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          (0, _user.updatePassword)(data).then(function (res) {
            if (res.status) {
              _this2.$message({
                message: "Change password successfully",
                type: "success",
                duration: 5 * 1000
              });

              _this2.$refs["form"].resetFields();
            } else {
              _this2.$message({
                message: res.message,
                type: "error",
                duration: 5 * 1000
              });
            }
          }).finally(function () {
            _this2.loadingButton = false;
          });
        } else {
          _this2.loadingButton = false;
        }
      });
    }
  }
};
exports.default = _default;