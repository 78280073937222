"use strict";

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.regexp.test.js");

require("core-js/modules/es.function.name.js");

var _xlsx = _interopRequireDefault(require("xlsx"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    indexSheetName: {
      type: Number,
      default: 0
    },
    beforeUpload: Function,
    // eslint-disable-line
    onSuccess: Function // eslint-disable-line

  },
  data: function data() {
    return {
      loading: false,
      excelData: {
        header: null,
        results: null
      }
    };
  },
  methods: {
    generateData: function generateData(_ref) {
      var header = _ref.header,
          results = _ref.results;
      this.excelData.header = header;
      this.excelData.results = results;
      this.onSuccess && this.onSuccess(this.excelData);
    },
    handleDrop: function handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.loading) return;
      var files = e.dataTransfer.files;

      if (files.length !== 1) {
        this.$message.error("Only support uploading one file!");
        return;
      }

      var rawFile = files[0]; // only use files[0]

      if (!this.isExcel(rawFile)) {
        this.$message.error("Only supports upload .xlsx, .xls, .csv suffix files");
        return false;
      }

      this.upload(rawFile);
      e.stopPropagation();
      e.preventDefault();
    },
    handleDragover: function handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = "copy";
    },
    handleUpload: function handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    handleClick: function handleClick(e) {
      var files = e.target.files;
      var rawFile = files[0]; // only use files[0]

      if (!rawFile) return;
      this.upload(rawFile);
    },
    upload: function upload(rawFile) {
      this.$refs["excel-upload-input"].value = null; // fix can't select the same excel

      if (!this.beforeUpload) {
        this.readerData(rawFile);
        return;
      }

      var before = this.beforeUpload(rawFile);

      if (before) {
        this.readerData(rawFile);
      }
    },
    readerData: function readerData(rawFile) {
      var _this = this;

      this.loading = true;
      return new Promise(function (resolve, reject) {
        var reader = new FileReader();

        reader.onload = function (e) {
          var data = e.target.result;

          var workbook = _xlsx.default.read(data, {
            type: "array"
          });

          var firstSheetName = workbook.SheetNames[_this.indexSheetName];
          var worksheet = workbook.Sheets[firstSheetName];

          var header = _this.getHeaderRow(worksheet);

          var results = _xlsx.default.utils.sheet_to_json(worksheet, {
            raw: false
          });

          _this.generateData({
            header: header,
            results: results
          });

          _this.loading = false;
          resolve();
        };

        reader.readAsArrayBuffer(rawFile);
      });
    },
    getHeaderRow: function getHeaderRow(sheet) {
      var headers = [];

      var range = _xlsx.default.utils.decode_range(sheet["!ref"]);

      var C;
      var R = range.s.r;
      /* start in the first row */

      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        var cell = sheet[_xlsx.default.utils.encode_cell({
          c: C,
          r: R
        })];
        /* find the cell in the first row */


        var hdr = "UNKNOWN " + C; // <-- replace with your desired default

        if (cell && cell.t) hdr = _xlsx.default.utils.format_cell(cell);
        headers.push(hdr);
      }

      return headers;
    },
    isExcel: function isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name);
    }
  }
};
exports.default = _default;