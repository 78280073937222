var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _vm.alert.success
        ? _c("el-alert", {
            attrs: { title: _vm.alert.success, type: "success" },
          })
        : _vm._e(),
      _vm.alert.error
        ? _c("el-alert", { attrs: { title: _vm.alert.error, type: "error" } })
        : _vm._e(),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, span: 8 } },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Imei:")]),
              _vm.totalRecord
                ? _c(
                    "span",
                    {
                      staticStyle: {
                        float: "right",
                        "background-color": "#dff0d8",
                      },
                    },
                    [
                      _vm._v(
                        "Total " + _vm._s(_vm.totalRecord) + " imei insert"
                      ),
                    ]
                  )
                : _vm._e(),
              _c("el-input", {
                staticStyle: { "margin-top": "10px" },
                attrs: {
                  type: "textarea",
                  rows: 7,
                  resize: "none",
                  placeholder: "Please input",
                },
                model: {
                  value: _vm.objData.imei,
                  callback: function ($$v) {
                    _vm.$set(_vm.objData, "imei", $$v)
                  },
                  expression: "objData.imei",
                },
              }),
              _c("p", [_vm._v("Please enter imei by new line")]),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, span: 12 } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("1.Master agent"),
                      ]),
                      _c(
                        "el-select",
                        {
                          ref: "agency-lv1",
                          staticStyle: { "margin-top": "10px", width: "100%" },
                          attrs: { filterable: "", placeholder: "Select" },
                          model: {
                            value: _vm.agency.lv1,
                            callback: function ($$v) {
                              _vm.$set(_vm.agency, "lv1", $$v)
                            },
                            expression: "agency.lv1",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "--Select--", value: null },
                          }),
                          _vm._l(_vm.arrAcengy.lv1, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("2. Distributor"),
                      ]),
                      _c(
                        "el-select",
                        {
                          ref: "agency-lv2",
                          staticStyle: { "margin-top": "10px", width: "100%" },
                          attrs: {
                            filterable: "",
                            disabled: !_vm.agency.lv1,
                            placeholder: "Select",
                          },
                          model: {
                            value: _vm.agency.lv2,
                            callback: function ($$v) {
                              _vm.$set(_vm.agency, "lv2", $$v)
                            },
                            expression: "agency.lv2",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "--Select--", value: null },
                          }),
                          _vm._l(_vm.arrAcengy.lv2, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("3. Retailer"),
                      ]),
                      _c(
                        "el-select",
                        {
                          ref: "agency-lv3",
                          staticStyle: { "margin-top": "10px", width: "100%" },
                          attrs: {
                            filterable: "",
                            disabled: !_vm.agency.lv2,
                            placeholder: "Select",
                          },
                          model: {
                            value: _vm.agency.lv3,
                            callback: function ($$v) {
                              _vm.$set(_vm.agency, "lv3", $$v)
                            },
                            expression: "agency.lv3",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "--Select--", value: null },
                          }),
                          _vm._l(_vm.arrAcengy.lv3, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("4. Employee"),
                      ]),
                      _c(
                        "el-select",
                        {
                          ref: "agency-lv4",
                          staticStyle: { "margin-top": "10px", width: "100%" },
                          attrs: {
                            filterable: "",
                            placeholder: "Select",
                            disabled: !_vm.agency.lv3,
                          },
                          model: {
                            value: _vm.agency.lv4,
                            callback: function ($$v) {
                              _vm.$set(_vm.agency, "lv4", $$v)
                            },
                            expression: "agency.lv4",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "--Select--", value: null },
                          }),
                          _vm._l(_vm.arrAcengy.lv4, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 24 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.buttonLoading,
                              expression: "buttonLoading",
                            },
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.createData },
                        },
                        [_vm._v("Submit")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("hr"),
      _c("h3", [_vm._v("Inventory ESN Re-assign by Agent Batch Report")]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading",
            },
          ],
          attrs: { data: _vm.tableData, border: "", stripe: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: { label: "Batch code", prop: "batchCode" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("el-link", [_vm._v(_vm._s(row.batchCode))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "created_at", label: "Uploaded On" },
          }),
          _c("el-table-column", {
            attrs: { prop: "asigneeName", label: "Uploaded By" },
          }),
          _c("el-table-column", {
            attrs: { prop: "receiverName", label: "Asigned To" },
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "text-center",
              label: "Total records",
              prop: "totalRecord",
            },
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "text-center",
              label: "Uploaded Success",
              prop: "uploadSuccess",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "success" },
                        on: {
                          click: function ($event) {
                            row.uploadSuccess
                              ? _vm.viewDetail("SUCCESS", row.id)
                              : false
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.uploadSuccess))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "text-center",
              label: "Uploaded Failure",
              prop: "uploadFail",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "danger" },
                        on: {
                          click: function ($event) {
                            row.uploadFail
                              ? _vm.viewDetail("FAIL", row.id)
                              : false
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.uploadFail))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }