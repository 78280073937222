"use strict";

var _interopRequireWildcard = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.object.keys.js");

require("core-js/modules/es.array.filter.js");

require("C:\\Projects\\008-QLK_FE\\node_modules\\@vue\\babel-preset-app\\node_modules\\core-js\\modules\\es.array.iterator.js");

require("C:\\Projects\\008-QLK_FE\\node_modules\\@vue\\babel-preset-app\\node_modules\\core-js\\modules\\es.promise.js");

require("C:\\Projects\\008-QLK_FE\\node_modules\\@vue\\babel-preset-app\\node_modules\\core-js\\modules\\es.object.assign.js");

require("C:\\Projects\\008-QLK_FE\\node_modules\\@vue\\babel-preset-app\\node_modules\\core-js\\modules\\es.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("./styles/element-variables.scss");

require("@/styles/index.scss");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));

var _locale = _interopRequireDefault(require("element-ui/lib/locale"));

require("./icons");

require("./permission");

require("./utils/error-log");

var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));

var _vueTheMask = _interopRequireDefault(require("vue-the-mask"));

var _vueHtmlToPaper = _interopRequireDefault(require("vue-html-to-paper"));

var filters = _interopRequireWildcard(require("./filters"));

// a modern alternative to CSS resets
// global css
// icon
// permission control
// error log
// global filters
_locale.default.use(_en.default);

_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium' // set element-ui default size

});

_vue.default.use(_vueClipboard.default);

_vue.default.use(_vueTheMask.default);

_vue.default.use(_vueHtmlToPaper.default, {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: ['https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css', 'https://unpkg.com/kidlat-css/css/kidlat.css']
}); // register global utility filters


Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});