var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "app-container",
      attrs: { "element-loading-text": "Processing data, don't refresh page" },
    },
    [
      _vm.roles.includes("admin")
        ? [
            _c("upload-excel-component", {
              staticStyle: { "margin-bottom": "10px" },
              attrs: {
                indexSheetName: 0,
                "on-success": _vm.handleSuccess,
                "before-upload": _vm.beforeUpload,
              },
            }),
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("p", { staticClass: "text-center" }, [
                  _vm._v("Upload sale report at here"),
                ]),
                _c("el-date-picker", {
                  attrs: {
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    type: "datetime",
                    placeholder: "Select date and time",
                  },
                  model: {
                    value: _vm.dateUpload,
                    callback: function ($$v) {
                      _vm.dateUpload = $$v
                    },
                    expression: "dateUpload",
                  },
                }),
                _c(
                  "el-button",
                  {
                    attrs: { type: "warning" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.open({ name: "sale-import-history" })
                      },
                    },
                  },
                  [_vm._v("History import")]
                ),
              ],
              1
            ),
            _c("hr"),
          ]
        : _vm._e(),
      _c("agency", { ref: "agency", attrs: { showAll: _vm.isAdmin } }),
      _c(
        "div",
        {
          staticClass: "filter-container",
          staticStyle: { "margin-top": "12px" },
        },
        [
          _c("div", { staticClass: "filter-item" }, [
            _c("label", { attrs: { for: "" } }, [_vm._v("Select date:")]),
            _c(
              "div",
              [
                _c("el-date-picker", {
                  attrs: {
                    format: "yyyy-MM-dd",
                    "value-format": "yyyy-MM-dd",
                    type: "daterange",
                    "range-separator": "To",
                    "start-placeholder": "Start date",
                    "end-placeholder": "End date",
                  },
                  on: { change: _vm.changeDate },
                  model: {
                    value: _vm.dateRange,
                    callback: function ($$v) {
                      _vm.dateRange = $$v
                    },
                    expression: "dateRange",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "filter-item",
              staticStyle: { transform: "translateY(9px)" },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loadingButton, type: "primary" },
                  on: { click: _vm.doSearch },
                },
                [_vm._v("Search")]
              ),
            ],
            1
          ),
          _vm.tableData.length
            ? _c(
                "div",
                {
                  staticClass: "filter-item",
                  staticStyle: { transform: "translateY(9px)" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        loading: _vm.loadingButtonExcel,
                        type: "success",
                      },
                      on: { click: _vm.downloadReport },
                    },
                    [_vm._v("Download report")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", "margin-top": "20px" },
          attrs: {
            data: _vm.tableData,
            border: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", { attrs: { type: "index", label: "#" } }),
          _c("el-table-column", {
            attrs: { label: "Master Agent", prop: "lv1Name" },
          }),
          _c("el-table-column", {
            attrs: { label: "Distributor", prop: "lv2Name" },
          }),
          _c("el-table-column", {
            attrs: { label: "Retailer", prop: "lv3Name" },
          }),
          _c("el-table-column", {
            attrs: { label: "Employee", prop: "lv4Name" },
          }),
          _c("el-table-column", {
            attrs: { label: "Seller", prop: "sellerName" },
          }),
          _c("el-table-column", {
            attrs: { label: "Enrollment number", prop: "enrollmentNumber" },
          }),
          _c("el-table-column", {
            attrs: { label: "Active Time", prop: "activeTime" },
          }),
          _c("el-table-column", {
            attrs: { label: "First call made", prop: "firstCallMade" },
          }),
          _c("el-table-column", {
            attrs: { label: "First call date", prop: "firstCallDate" },
          }),
          _c("el-table-column", { attrs: { label: "ESN", prop: "esn" } }),
          _c("el-table-column", { attrs: { label: "Emei", prop: "imei" } }),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              label: "Found in inventory",
              prop: "foundInInventory",
            },
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.doSearch,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }