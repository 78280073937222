var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "250px" },
            attrs: {
              "prefix-icon": "el-icon-search",
              placeholder: "Search name or username",
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.getList($event)
              },
            },
            model: {
              value: _vm.listQuery.search,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "search", $$v)
              },
              expression: "listQuery.search",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.getList()
                },
              },
            },
            [_vm._v("Search")]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: {
                click: function ($event) {
                  return _vm.handleAdd()
                },
              },
            },
            [_vm._v("Add")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "technician-table",
          attrs: {
            data: _vm.arrData,
            border: "",
            "highlight-current-row": "",
            fit: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Name", width: "200px", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "username",
              width: "200px",
              prop: "username",
              label: "Username",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [_vm._v(_vm._s(row.username))]),
                    row.isActive
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("Active"),
                        ])
                      : _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("Block"),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Level" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(_vm._s(row.level ? row.level.name : ""))]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { prop: "email", label: "Email" } }),
          _c("el-table-column", {
            attrs: { label: "Created date", prop: "created_at" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "Action",
              width: "230",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            _vm.getData(row)
                            _vm.getTreeRoot(row.id)
                            _vm.dialogStatus = "edit"
                          },
                        },
                      },
                      [_vm._v("Edit")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.resetPassword(row.id)
                          },
                        },
                      },
                      [_vm._v("Reset")]
                    ),
                    row.isActive
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleBlock(row.id)
                              },
                            },
                          },
                          [_vm._v("Block")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { type: "warning", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUnBlock(row.id)
                              },
                            },
                          },
                          [_vm._v("Unblock")]
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            top: "15px",
            title: _vm.handleTitleDialog(),
            visible: _vm.dialogVisible,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
            },
            [
              _c(
                "el-tabs",
                { attrs: { type: "card" } },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Info" } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "dataForm",
                          attrs: {
                            rules: _vm.rules,
                            model: _vm.tempData,
                            "label-position": "left",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Name", prop: "name" } },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.tempData.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.tempData, "name", $$v)
                                          },
                                          expression: "tempData.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: { label: "Email", prop: "email" },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.tempData.email,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.tempData, "email", $$v)
                                          },
                                          expression: "tempData.email",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Username",
                                        prop: "username",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.tempData.username,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tempData,
                                              "username",
                                              $$v
                                            )
                                          },
                                          expression: "tempData.username",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.dialogStatus != "edit"
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Password",
                                            prop: "password",
                                          },
                                        },
                                        [
                                          _vm.dialogStatus != "edit"
                                            ? _c("el-input", {
                                                model: {
                                                  value: _vm.tempData.password,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.tempData,
                                                      "password",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "tempData.password",
                                                },
                                              })
                                            : _c("el-input", {
                                                attrs: {
                                                  disabled: "",
                                                  value: "*****",
                                                  placeholder: "",
                                                },
                                              }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticStyle: { "line-height": "36px" },
                                      attrs: { for: "" },
                                    },
                                    [_vm._v("Agency level")]
                                  ),
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: _vm.dialogStatus == "edit",
                                        placeholder: "",
                                      },
                                      model: {
                                        value: _vm.tempData.agencyLevelId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.tempData,
                                            "agencyLevelId",
                                            $$v
                                          )
                                        },
                                        expression: "tempData.agencyLevelId",
                                      },
                                    },
                                    _vm._l(_vm.agencyLevel, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.id + ". " + item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.tempData.agencyLevelId &&
                              _vm.tempData.agencyLevelId != 1
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticStyle: {
                                            "line-height": "36px",
                                          },
                                          attrs: { for: "" },
                                        },
                                        [_vm._v("Parent")]
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            disabled:
                                              _vm.dialogStatus == "edit",
                                            filterable: "",
                                            placeholder: "",
                                          },
                                          model: {
                                            value: _vm.tempData.parentId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tempData,
                                                "parentId",
                                                $$v
                                              )
                                            },
                                            expression: "tempData.parentId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.arrUserTemp,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label:
                                                  item.name +
                                                  " (" +
                                                  item.username +
                                                  ")",
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.dialogStatus == "edit"
                    ? _c(
                        "el-tab-pane",
                        { attrs: { label: "Tree" } },
                        [
                          _c("el-tree", {
                            attrs: {
                              data: _vm.rootTree,
                              props: _vm.defaultProps,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "edit"
                        ? _vm.editData()
                        : _vm.createData()
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }