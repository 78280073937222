"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

var _validate = require("@/utils/validate");

var _auth = require("@/utils/auth");

//
//
//
//
//
//
//
//
var _default = {
  props: {
    to: {
      type: String,
      required: true
    }
  },
  methods: {
    linkProps: function linkProps(url) {
      if ((0, _validate.isExternal)(url)) {
        if (url.includes(process.env.VUE_APP_LEAD_URL)) {
          url += "?token=".concat((0, _auth.getToken)());
        }

        return {
          is: "a",
          href: url,
          target: "_blank",
          rel: "noopener"
        };
      }

      return {
        is: "router-link",
        to: url
      };
    }
  }
};
exports.default = _default;