var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v(" Search ")]),
            _c("el-input", {
              attrs: { placeholder: "Search name" },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.fetchData($event)
                },
              },
              model: {
                value: _vm.query.search,
                callback: function ($$v) {
                  _vm.$set(_vm.query, "search", $$v)
                },
                expression: "query.search",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Date")]),
            _c("br"),
            _c("el-date-picker", {
              attrs: { type: "daterange" },
              on: { change: _vm.fetchData },
              model: {
                value: _vm.date,
                callback: function ($$v) {
                  _vm.date = $$v
                },
                expression: "date",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                staticStyle: {
                  transform: "translateY(11px)",
                  "font-size": "15px",
                },
                attrs: {
                  type: "primary",
                  size: "small",
                  icon: "el-icon-search",
                },
                on: { click: _vm.fetchData },
              },
              [_vm._v("Search")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", { attrs: { label: "No.", type: "index" } }),
          _c("el-table-column", { attrs: { label: "Name", prop: "name" } }),
          _c("el-table-column", {
            attrs: { label: "Staff receive" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.user
                      ? _c("span", [_vm._v(" " + _vm._s(row.user.name) + " ")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Total", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.viewDetail(row)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(row.total) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Date", prop: "created_at" },
          }),
          _c("el-table-column", {
            attrs: { label: "Action" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdateRmaNumber(row)
                          },
                        },
                      },
                      [_vm._v("Update RMA Number")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("receive-imei-detail-modal", { ref: "ReceiveImeiDetailModal" }),
      _c("update-rma-number-modal", { ref: "UpdateRmaNumberModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }