"use strict";

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/objectSpread2.js"));

var _vuex = require("vuex");

var _Account = _interopRequireDefault(require("./components/Account"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Profile",
  components: {
    Account: _Account.default
  },
  data: function data() {
    return {
      user: {},
      activeTab: "account"
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["name", "username"])),
  methods: {}
};
exports.default = _default;