var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("agency", { ref: "agency", on: { "init:ok": _vm.doSearch } }),
      _c(
        "div",
        {
          staticClass: "filter-container",
          staticStyle: { "margin-top": "12px" },
        },
        [
          _c("div", { staticClass: "filter-item" }, [
            _c("label", { attrs: { for: "" } }, [_vm._v("Select date:")]),
            _c(
              "div",
              [
                _c("el-date-picker", {
                  attrs: {
                    format: "yyyy-MM-dd",
                    "value-format": "yyyy-MM-dd",
                    type: "daterange",
                    "range-separator": "To",
                    "start-placeholder": "Start date",
                    "end-placeholder": "End date",
                  },
                  on: { change: _vm.doSearch },
                  model: {
                    value: _vm.dateRange,
                    callback: function ($$v) {
                      _vm.dateRange = $$v
                    },
                    expression: "dateRange",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "filter-item",
              staticStyle: { transform: "translateY(9px)" },
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.doSearch } },
                [_vm._v("Search")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", { attrs: { label: "No.", type: "index" } }),
          _c("el-table-column", {
            attrs: { label: "Agency request", prop: "user.name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [_vm._v(_vm._s(row.user.name))]),
                    row.user.agencyLevelId
                      ? _c("span", [
                          _vm._v(
                            " (Lv." + _vm._s(row.user.agencyLevelId) + ")"
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Date", prop: "created_at" },
          }),
          _c("el-table-column", {
            attrs: { label: "Success", prop: "success", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "Fail", prop: "fail", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "Expired", prop: "expired", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "Total", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.onClickOpen(row)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(row.total) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }