var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    { attrs: { data: _vm.data, border: "", fit: "" } },
    [
      _c("el-table-column", { attrs: { label: "No.", type: "index" } }),
      _c("el-table-column", { attrs: { label: "Imei", prop: "imei" } }),
      _c("el-table-column", {
        attrs: { label: "Device type", prop: "product.model.type.name" },
      }),
      _c("el-table-column", {
        attrs: { label: "Device model", prop: "product.model.name" },
      }),
      _c("el-table-column", {
        attrs: { label: "Removed" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                row.product
                  ? [
                      row.product.active
                        ? _c("label", { attrs: { for: "" } }, [_vm._v("No")])
                        : _c("label", { attrs: { for: "" } }, [_vm._v("Yes")]),
                    ]
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "Status", sortable: "", prop: "status" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                row.status == _vm.ImeiRequestStatus.expired
                  ? _c(
                      "label",
                      {
                        staticStyle: { color: "rgb(230, 162, 60)" },
                        attrs: { for: "" },
                      },
                      [_vm._v(" Expired 90 days ")]
                    )
                  : row.status == _vm.ImeiRequestStatus.valid
                  ? _c("label", { staticStyle: { color: "#67C23A" } }, [
                      _vm._v("Valid"),
                    ])
                  : _c("label", { staticStyle: { color: "#F56C6C" } }, [
                      _vm._v("Invalid"),
                    ]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", { attrs: { label: "Note", prop: "note" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }