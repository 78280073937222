"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _auth = require("@/utils/auth");

//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      leadUrl: process.env.VUE_APP_LEAD_URL,
      url: ""
    };
  },
  mounted: function mounted() {
    this.url = this.leadUrl + "/staff/working-history" + "?token=".concat((0, _auth.getToken)());
  }
};
exports.default = _default;