var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c("div", { staticStyle: { "text-align": "center" } }, [
      _c("label", { staticClass: "date", attrs: { for: "" } }, [
        _vm._v(" Today is " + _vm._s(_vm.today) + " "),
      ]),
      _vm.isLoaded
        ? _c(
            "div",
            { staticStyle: { "margin-top": "16px" } },
            [
              !_vm.currentWorkSubmit
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "medium",
                        loading: _vm.loading,
                      },
                      on: { click: _vm.handleStartRecord },
                    },
                    [_vm._v(" Start record ")]
                  )
                : _c(
                    "div",
                    [
                      !_vm.currentWorkSubmit.to
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "medium",
                                loading: _vm.loading,
                              },
                              on: { click: _vm.handleEndRecord },
                            },
                            [_vm._v(" End record ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
              _vm._l(_vm.workSubmits, function (item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c("el-alert", {
                      staticStyle: { "margin-top": "8px" },
                      attrs: {
                        title: "" + _vm.startRecordLabel(item),
                        type: "success",
                        "show-icon": "",
                        center: "",
                        closable: false,
                      },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }