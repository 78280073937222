var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "app-container",
      attrs: { "element-loading-text": "Processing data, don't refresh page" },
    },
    [
      _c("agency", { ref: "agency" }),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { staticStyle: { "margin-top": "10px" }, attrs: { span: 8 } },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Select date:")]),
              _c("el-date-picker", {
                attrs: {
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  type: "daterange",
                  "range-separator": "To",
                  "start-placeholder": "Start date",
                  "end-placeholder": "End date",
                },
                on: { change: _vm.changeDate },
                model: {
                  value: _vm.dateRange,
                  callback: function ($$v) {
                    _vm.dateRange = $$v
                  },
                  expression: "dateRange",
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticStyle: { "margin-top": "28px" },
              attrs: { span: 2, xs: 24 },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loadingButton, type: "primary" },
                  on: { click: _vm.doSearch },
                },
                [_vm._v("Search")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.tableData.length
        ? _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-top": "20px" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "highlight-current-row": "",
                "show-summary": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "#", width: "50px" },
              }),
              _c("el-table-column", {
                attrs: { label: "Master Agent", prop: "lv1Name" },
              }),
              _vm.level >= 2
                ? _c("el-table-column", {
                    attrs: { label: "Distributor", prop: "lv2Name" },
                  })
                : _vm._e(),
              _vm.level >= 3
                ? _c("el-table-column", {
                    attrs: { label: "Retailer", prop: "lv3Name" },
                  })
                : _vm._e(),
              _vm.level >= 4
                ? _c("el-table-column", {
                    attrs: { label: "Employee", prop: "lv4Name" },
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { label: "Last update", prop: "dateUpload" },
              }),
              _c("el-table-column", {
                attrs: { label: "Total activation", prop: "total" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDetail(row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(row.total))]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3908131396
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: function ($event) {
            return _vm.doSearch()
          },
        },
      }),
      _c("report-detail", {
        ref: "report-detail",
        attrs: {
          level: _vm.level,
          userId: _vm.listQuery.userId,
          dateFrom: _vm.listQuery.dateFrom,
          dateTo: _vm.listQuery.dateTo,
        },
        on: {
          "update:userId": function ($event) {
            return _vm.$set(_vm.listQuery, "userId", $event)
          },
          "update:user-id": function ($event) {
            return _vm.$set(_vm.listQuery, "userId", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }