var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "login-form",
      attrs: { model: _vm.dataForm, "label-width": "80px" },
    },
    [
      _c("div", { staticClass: "title-container text-center" }, [
        _c("img", {
          staticStyle: { filter: "brightness(5)" },
          attrs: {
            src: require("@/assets/images/logo.png"),
            width: "200",
            alt: "",
          },
        }),
        _c("h3", { staticStyle: { color: "white" } }, [
          _vm._v("Reset password"),
        ]),
      ]),
      _c(
        "el-form-item",
        {
          staticStyle: { "margin-left": "0" },
          attrs: { label: "", prop: "username" },
        },
        [
          _c(
            "span",
            { staticClass: "svg-container" },
            [_c("svg-icon", { attrs: { "icon-class": "user" } })],
            1
          ),
          _c("el-input", {
            staticClass: "input-disabled",
            attrs: { disabled: "" },
            model: {
              value: _vm.dataForm.username,
              callback: function ($$v) {
                _vm.$set(_vm.dataForm, "username", $$v)
              },
              expression: "dataForm.username",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "", prop: "password" } },
        [
          _c(
            "span",
            { staticClass: "svg-container" },
            [_c("svg-icon", { attrs: { "icon-class": "password" } })],
            1
          ),
          _c("el-input", {
            attrs: { type: "password", placeholder: "Input new password" },
            model: {
              value: _vm.dataForm.password,
              callback: function ($$v) {
                _vm.$set(
                  _vm.dataForm,
                  "password",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "dataForm.password",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "", prop: "rePassword" } },
        [
          _c(
            "span",
            { staticClass: "svg-container" },
            [_c("svg-icon", { attrs: { "icon-class": "password" } })],
            1
          ),
          _c("el-input", {
            attrs: { type: "password", placeholder: "Input renew password" },
            model: {
              value: _vm.dataForm.rePassword,
              callback: function ($$v) {
                _vm.$set(
                  _vm.dataForm,
                  "rePassword",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "dataForm.rePassword",
            },
          }),
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticStyle: { width: "100%", "margin-bottom": "30px" },
          attrs: { loading: _vm.loading, type: "primary" },
          nativeOn: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.handleSubmit($event)
            },
          },
        },
        [_vm._v("Submit")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }