var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Shipped order",
        visible: _vm.visible,
        width: "500px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Tracking number", prop: "trackingCode" } },
            [
              _c("el-input", {
                attrs: { size: "normal" },
                model: {
                  value: _vm.form.trackingCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "trackingCode", $$v)
                  },
                  expression: "form.trackingCode",
                },
              }),
            ],
            1
          ),
          _c("p", { staticStyle: { color: "#999" } }, [
            _c("i", [
              _vm._v(" If entering multiple codes add commas between codes "),
              _c("br"),
              _vm._v(" eg: 754DSF4152, 45752HGD324"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.submitData },
            },
            [_vm._v("Submit")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }