"use strict";

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addModel = addModel;
exports.addTye = addTye;
exports.deleteModel = deleteModel;
exports.deleteType = deleteType;
exports.getAllDevice = getAllDevice;
exports.getAllModel = getAllModel;
exports.getAllType = getAllType;
exports.getType = getType;
exports.updateModel = updateModel;
exports.updateType = updateType;

var _request = _interopRequireDefault(require("@/utils/request"));

function addTye(data) {
  return (0, _request.default)({
    url: '/device/type/add',
    method: 'post',
    data: data
  });
}

function getAllDevice() {
  return (0, _request.default)({
    url: '/device/all',
    method: 'get'
  });
}

function addModel(data) {
  return (0, _request.default)({
    url: '/device/model/add',
    method: 'post',
    data: data
  });
}

function deleteType(typeId) {
  return (0, _request.default)({
    url: "/device/type/".concat(typeId, "/delete"),
    method: 'post'
  });
}

function deleteModel(modelId) {
  return (0, _request.default)({
    url: "/device/model/".concat(modelId, "/delete"),
    method: 'post'
  });
}

function getAllType() {
  return (0, _request.default)({
    url: '/device/type/all',
    method: 'get'
  });
}

function getType(typeId) {
  return (0, _request.default)({
    url: "/device/type/".concat(typeId),
    method: 'get'
  });
}

function updateType(typeId, data) {
  return (0, _request.default)({
    url: "/device/type/".concat(typeId, "/update"),
    method: 'post',
    data: data
  });
}

function updateModel(modelId, data) {
  return (0, _request.default)({
    url: "/device/model/".concat(modelId, "/update"),
    method: 'post',
    data: data
  });
}

function getAllModel(typeId) {
  return (0, _request.default)({
    url: "/device/type/".concat(typeId, "/model"),
    method: 'get'
  });
}