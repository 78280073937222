"use strict";

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.asign = asign;
exports.detailHistory = detailHistory;
exports.getAgency = getAgency;
exports.getAgencyRoot = getAgencyRoot;
exports.history = history;

var _request = _interopRequireDefault(require("@/utils/request"));

function getAgency(parentId) {
  return (0, _request.default)({
    url: "/inventory/getAgencyChild/".concat(parentId),
    method: 'get'
  });
}

function getAgencyRoot() {
  return (0, _request.default)({
    url: "/inventory/getAgencyRoot",
    method: 'get'
  });
}

function asign(data) {
  return (0, _request.default)({
    url: "/inventory/asign",
    method: 'post',
    data: data
  });
}

function history(query) {
  return (0, _request.default)({
    url: "/inventory/history",
    method: 'get',
    params: query
  });
}

function detailHistory(status, batchId) {
  return (0, _request.default)({
    url: "/inventory/history/detail/".concat(batchId),
    method: 'get',
    params: {
      status: status
    }
  });
}