var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "Warning remove", visible: _vm.visible, width: "500px" },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _vm._v(
        " Have " +
          _vm._s(_vm.imeis.length) +
          " imei(s) is used, do you want to remove? "
      ),
      _c(
        "div",
        { staticStyle: { padding: "12px 0" } },
        _vm._l(_vm.imeis, function (item, index) {
          return _c("span", { key: index }, [
            _vm._v(" - " + _vm._s(item) + " "),
            _c("br"),
          ])
        }),
        0
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Close")]
          ),
          _c(
            "el-button",
            { attrs: { type: "danger" }, on: { click: _vm.handleConfirm } },
            [_vm._v("Remove")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }