var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Serch device type" },
                        model: {
                          value: _vm.searchType,
                          callback: function ($$v) {
                            _vm.searchType = $$v
                          },
                          expression: "searchType",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "btn-add-service-group",
                  attrs: { type: "primary", icon: "el-icon-circle-plus" },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v("Add device type")]
              ),
              !_vm.arrTypeTemp.length
                ? _c("p", { staticClass: "text-center" }, [
                    _vm._v("No data..."),
                  ])
                : _c(
                    "div",
                    { staticClass: "service-group-box" },
                    _vm._l(_vm.arrTypeTemp, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          ref: "parent-" + index,
                          refInFor: true,
                          staticClass: "service-group-item",
                          class: {
                            active: item.id == _vm.deviceType.id ? true : false,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.getDeviceModel(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "info-box" }, [
                            _c("div", { staticClass: "info-item" }, [
                              _c("span", { staticClass: "value-info" }, [
                                _vm._v(_vm._s(item.name)),
                              ]),
                            ]),
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "btn-edit-service-group",
                              attrs: { title: "Edit" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEditDeviceType(item.id)
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-edit" })]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn-remove-service-group",
                              attrs: { title: "Remove" },
                              on: {
                                click: function ($event) {
                                  return _vm.remove(item.id)
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-remove-outline" })]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("model", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loadingGetData,
                    expression: "loadingGetData",
                  },
                ],
                ref: "deviceModel",
                on: {
                  "load:done": function ($event) {
                    _vm.loadingGetData = false
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogServiceGroupVisible,
            width: "500px",
            title:
              _vm.dialogServiceGroupStatus == "edit"
                ? "Edit device type"
                : "Add device type",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogServiceGroupVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formServiceGroup",
              attrs: {
                rules: _vm.rulesServiceGroup,
                model: _vm.deviceType,
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Name", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "Type device type name" },
                            model: {
                              value: _vm.deviceType.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.deviceType, "name", $$v)
                              },
                              expression: "deviceType.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogServiceGroupVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function ($event) {
                      _vm.dialogServiceGroupStatus == "edit"
                        ? _vm.editDeviceType()
                        : _vm.createDeviceType()
                    },
                  },
                },
                [_vm._v("Submit")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }