"use strict";

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllLevel = getAllLevel;

var _request = _interopRequireDefault(require("@/utils/request"));

function getAllLevel() {
  return (0, _request.default)({
    url: '/agency/level/all',
    method: 'get'
  });
}