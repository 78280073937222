"use strict";

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.imeiReceiveApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var imeiReceiveApi = {
  findImeis: function findImeis(params) {
    return (0, _request.default)({
      url: "/imei-receive/imeis",
      method: "get",
      params: params
    });
  },
  check: function check(data) {
    return (0, _request.default)({
      url: "/imei-receive/check",
      method: "post",
      data: data
    });
  },
  update: function update(data) {
    return (0, _request.default)({
      url: "/imei-receive/update",
      method: "post",
      data: data
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/imei-receive",
      method: "post",
      data: data
    });
  },
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/imei-receive",
      params: params
    });
  },
  findDetail: function findDetail(id, params) {
    return (0, _request.default)({
      url: "/imei-receive/".concat(id, "/detail"),
      params: params
    });
  }
};
exports.imeiReceiveApi = imeiReceiveApi;