"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatCurrency = formatCurrency;
exports.formatNumberVN = formatNumberVN;

function formatCurrency(n) {
  return new Intl.NumberFormat("US", {
    style: "currency",
    currency: "USD"
  }).format(n);
}

function formatNumberVN(n) {
  return new Intl.NumberFormat("Vi", {
    style: "decimal"
  }).format(n);
}