var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Imei:")]),
              _vm.totalRecord
                ? _c(
                    "span",
                    {
                      staticStyle: {
                        float: "right",
                        "background-color": "#dff0d8",
                      },
                    },
                    [
                      _vm._v(
                        "Total " + _vm._s(_vm.totalRecord) + " imei insert"
                      ),
                    ]
                  )
                : _vm._e(),
              _c("el-input", {
                staticStyle: { "margin-top": "10px" },
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 7, maxRows: 12 },
                  placeholder: "Please input",
                },
                model: {
                  value: _vm.objData.imei,
                  callback: function ($$v) {
                    _vm.$set(_vm.objData, "imei", $$v)
                  },
                  expression: "objData.imei",
                },
              }),
              _c("p", [_vm._v("Please enter imei by new line")]),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 24 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-top": "17px" },
                          attrs: {
                            type: "primary",
                            disabled: !_vm.totalRecord,
                            loading: _vm.loadingCheck,
                          },
                          on: { click: _vm.checkImeis },
                        },
                        [_vm._v("Check Imeis")]
                      ),
                      _c("div", { staticStyle: { "margin-top": "12px" } }, [
                        _c("div", [
                          _c("span", { attrs: { for: "" } }, [
                            _vm._v("Valid imei: "),
                          ]),
                          _c("label", { staticStyle: { color: "#67C23A" } }, [
                            _vm._v(_vm._s(_vm.successImeis.length)),
                          ]),
                        ]),
                        _c("div", { staticStyle: { "margin-top": "4px" } }, [
                          _c("span", [_vm._v("Invalid imei: ")]),
                          _c("label", { staticStyle: { color: "#F56C6C" } }, [
                            _vm._v(_vm._s(_vm.failImeis.length)),
                          ]),
                        ]),
                        _c("div", { staticStyle: { "margin-top": "4px" } }, [
                          _c("span", [_vm._v("Expired 90 days: ")]),
                          _c(
                            "label",
                            { staticStyle: { color: "rgb(230, 162, 60)" } },
                            [_vm._v(_vm._s(_vm.expiredImeis.length))]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "12px" },
          attrs: {
            type: "primary",
            disabled: !_vm.successImeis.length,
            loading: _vm.loadingSubmit,
          },
          on: { click: _vm.handleRequest },
        },
        [_vm._v("Request imeis")]
      ),
      _c("imei-request-check-table", { attrs: { data: _vm.checkImeiList } }),
      _c(
        "el-collapse",
        { staticStyle: { "margin-bottom": "12px" } },
        [
          _c(
            "el-collapse-item",
            { attrs: { title: "Search Imei" } },
            [_c("request-imei-by-agency")],
            1
          ),
          _c(
            "el-collapse-item",
            { attrs: { title: "Request history" } },
            [
              _c("imei-request-history-table", {
                attrs: {
                  data: _vm.data,
                  loading: _vm.listLoading,
                  onClickOpen: _vm.handleOpen,
                },
                on: { query: _vm.handleQuery },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("request-imei-modal", { ref: "RequestImeiModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }