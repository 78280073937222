var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "RMA Number Import",
        visible: _vm.visible,
        width: "700px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c("upload-excel", {
            staticStyle: { "margin-bottom": "10px" },
            attrs: { indexSheetName: 1, "on-success": _vm.handleSuccess },
          }),
          _c(
            "div",
            { staticStyle: { "padding-left": "28px" } },
            [
              _c(
                "el-link",
                { attrs: { href: "/RMA-Number.xlsx", type: "primary" } },
                [
                  _c("i", { staticClass: "el-icon-download" }),
                  _vm._v(" Download sample file"),
                ]
              ),
              _c("br"),
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Added " + _vm._s(_vm.data.length) + " records"),
              ]),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { "padding-left": "28px" },
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "RMA Number", prop: "rmaNumber" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Input RMA Number" },
                    model: {
                      value: _vm.form.rmaNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "rmaNumber", $$v)
                      },
                      expression: "form.rmaNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Close")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.loadingImport,
                type: "primary",
                disabled: !_vm.data.length,
              },
              on: { click: _vm.handleImport },
            },
            [_vm._v("Import data")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }