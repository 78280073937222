"use strict";

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

var _interopRequireWildcard = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.object.to-string.js");

var product = _interopRequireWildcard(require("@/api/product"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      totalAvailable: 0,
      tagOptions: {
        REMOVED: {
          type: "danger"
        },
        USED: {
          type: "info"
        },
        FREE: {
          type: "success"
        }
      },
      dateRange: [(0, _moment.default)().format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")],
      tableData: [],
      total: 0,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        status: ""
      },
      tableLoading: false,
      imeiSelect: []
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    removeImei: function removeImei() {
      var _this = this;

      this.$prompt("", "Cause remove", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        inputType: "textarea"
      }).then(function (value) {
        var data = {
          cause: value.value,
          imei: _this.imeiSelect
        };
        product.removeImei(data).then(function (res) {
          _this.$message({
            type: "success",
            message: res.message
          });

          _this.getTableData();
        });
      });
    },
    handleSelect: function handleSelect(val) {
      var imeis = val.map(function (item) {
        return item.imei;
      });
      this.imeiSelect = imeis;
    },
    changeDate: function changeDate(val) {
      this.listQuery.dateFrom = val[0];
      this.listQuery.dateTo = val[1];
      this.getAllInventory();
    },
    getTableData: function getTableData() {
      var _this2 = this;

      this.tableLoading = true;
      product.getImeis(this.listQuery).then(function (res) {
        _this2.totalAvailable = res.data.available;
        _this2.tableData = res.data.data.data;
        _this2.total = res.data.data.total;
      }).finally(function () {
        _this2.tableLoading = false;
      });
    }
  }
};
exports.default = _default;