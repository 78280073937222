"use strict";

var _interopRequireWildcard = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/objectSpread2.js"));

require("core-js/modules/es.error.cause.js");

require("core-js/modules/es.object.to-string.js");

var _validate = require("@/utils/validate");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var order = _interopRequireWildcard(require("@/api/order"));

var user = _interopRequireWildcard(require("@/api/user"));

var _approveModal = _interopRequireDefault(require("./components/approveModal.vue"));

var _trackingModal = _interopRequireDefault(require("./components/trackingModal.vue"));

var _orderType = require("@/types/orderType");

var _format = require("@/utils/format");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    ApproveModal: _approveModal.default,
    TrackingModal: _trackingModal.default
  },
  data: function data() {
    var validateEmail = function validateEmail(rule, value, callback) {
      if (!value) {
        callback(new Error("Email is required"));
      } else if (!(0, _validate.validEmail)(value)) {
        callback(new Error("Email is invalid"));
      } else {
        callback();
      }
    };

    return {
      orderType: _orderType.orderType,
      orderStatus: _orderType.orderStatus,
      orderStatusColor: _orderType.orderStatusColor,
      dialogLoading: false,
      defaultProps: {
        children: "children",
        label: "label"
      },
      listLoading: false,
      agencyLoading: false,
      total: 0,
      loadingButton: false,
      listQuery: {
        page: 1,
        limit: 10,
        search: ""
      },
      agencyQuery: {
        page: 1,
        limit: 50,
        search: "",
        level: 1
      },
      agencies: [],
      arrData: [],
      dialogVisible: false,
      tempData: {
        name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        type: "",
        amount: "",
        note: ""
      },
      dialogStatus: "",
      rules: {
        name: {
          required: true,
          message: "Name is required",
          trigger: "submit"
        },
        phone: {
          required: true,
          message: "Phone is required",
          trigger: "submit"
        },
        address: {
          required: true,
          message: "Address is required",
          trigger: "submit"
        },
        type: {
          required: true,
          message: "Type is required",
          trigger: "submit"
        },
        amount: {
          required: true,
          message: "Amount is required",
          trigger: "submit"
        },
        city: {
          required: true,
          message: "City is required",
          trigger: "submit"
        },
        state: {
          required: true,
          message: "State is required",
          trigger: "submit"
        },
        zipCode: {
          required: true,
          message: "Zip code is required",
          trigger: "submit"
        },
        companyName: {
          required: true,
          message: "Company name is required",
          trigger: "submit"
        },
        email: {
          required: true,
          trigger: "submit",
          validator: validateEmail
        }
      },
      isAdmin: false
    };
  },
  created: function created() {
    this.getList();
    this.getAgencies();
    this.isAdmin = this.$store.state.user.info.role == "admin";
  },
  methods: {
    handleDeny: function handleDeny(row) {
      var _this = this;

      this.$confirm("This order will be denied. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(function () {
        order.update(row.id, {
          order: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, row), {}, {
            status: _orderType.orderStatus.Denied
          })
        }).then(function () {
          _this.getList();

          _this.$message({
            type: "success",
            message: "Deny order successfully"
          });
        });
      });
    },
    handleOrdered: function handleOrdered(row) {
      var _this2 = this;

      this.$confirm("This order will be ordered. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(function () {
        order.update(row.id, {
          order: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, row), {}, {
            status: _orderType.orderStatus.Ordered
          })
        }).then(function () {
          _this2.getList();

          _this2.$message({
            type: "success",
            message: "Update status successfully"
          });
        });
      });
    },
    handleUpdateStatus: function handleUpdateStatus(orderData, type) {
      switch (type) {
        case "approve":
          this.$refs["approveModal"].handleOpen(orderData);
          break;

        case "shipped":
          this.$refs["trackingModal"].handleOpen(orderData);
          break;
      }
    },
    resetTempData: function resetTempData() {
      this.tempData = {
        name: "",
        phone: "",
        address: "",
        email: "",
        type: "",
        amount: "",
        city: "",
        state: "",
        zipCode: ""
      };
    },
    getData: function getData(row) {
      var _this3 = this;

      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this3.$refs["dataForm"].clearValidate();
      });
      this.tempData = (0, _objectSpread2.default)({}, row);
    },
    editData: function editData() {
      var _this4 = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          _this4.loadingButton = true;
          var data = {
            order: _this4.tempData
          };
          var orderId = _this4.tempData.id;
          order.update(orderId, data).then(function (res) {
            _this4.$message({
              message: "Edit successfully!",
              type: "success"
            });

            _this4.getList();

            _this4.dialogVisible = false;
          }).finally(function () {
            _this4.loadingButton = false;
          });
        }
      });
    },
    createData: function createData() {
      var _this5 = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          _this5.loadingButton = true;
          user.register(_this5.tempData).then(function (res) {
            _this5.$message({
              message: "Create successfully!",
              type: "success"
            });

            _this5.getList();

            _this5.dialogVisible = false;
          }).finally(function () {
            _this5.loadingButton = false;
          });
        }
      });
    },
    handleAdd: function handleAdd() {
      var _this6 = this;

      this.dialogStatus = "add";
      this.dialogVisible = true;
      this.avatar = "";
      this.workdate = [];
      this.resetTempData(); // this.tempData.password = randomString(6);

      this.$nextTick(function () {
        _this6.$refs["dataForm"].clearValidate();
      });
    },
    handleUpdateOrder: function handleUpdateOrder(orderData) {
      this.getData(orderData);
    },
    handleTitleDialog: function handleTitleDialog() {
      if (this.dialogStatus == "edit") {
        return "Info order";
      }

      return "Add order";
    },
    getList: function getList() {
      var _this7 = this;

      this.listLoading = true;
      order.findAll(this.listQuery).then(function (res) {
        _this7.arrData = res.data.orders;
        _this7.total = res.data.total;
      }).finally(function () {
        _this7.listLoading = false;
      });
    },
    getAgencies: function getAgencies() {
      var _this8 = this;

      this.agencyLoading = true;
      user.getAllUser(this.agencyQuery).then(function (res) {
        _this8.agencies = res.data.data;
      }).finally(function () {
        _this8.agencyLoading = false;
      });
    },
    searchAgency: function searchAgency(keyword) {
      var _this9 = this;

      this.agencyLoading = true;
      user.getAllUser((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.agencyQuery), {}, {
        search: keyword
      })).then(function (res) {
        _this9.agencies = res.data.data;
      }).finally(function () {
        _this9.agencyLoading = false;
      });
    },
    formatNumberVN: function formatNumberVN(n) {
      return (0, _format.formatNumberVN)(n);
    }
  }
};
exports.default = _default;