"use strict";

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;

require("core-js/modules/es.object.to-string.js");

var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _layout = _interopRequireDefault(require("@/layout"));

_vueRouter.default.prototype.open = function (routeObject) {
  var _this$resolve = this.resolve(routeObject),
      href = _this$resolve.href;

  window.open(href, "_blank");
};

_vue.default.use(_vueRouter.default);
/* Layout */


/* Router Modules */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = [{
  path: "/redirect",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "/redirect/:path*",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/redirect/index"));
      });
    }
  }]
}, {
  path: "/",
  redirect: "inventory/index"
}, {
  path: "/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/index"));
    });
  },
  hidden: true
}, {
  path: "/auth-redirect",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/auth-redirect"));
    });
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/404"));
    });
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/401"));
    });
  },
  hidden: true
}, {
  path: "/profile",
  component: _layout.default,
  redirect: "/profile/index",
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/profile/index"));
      });
    },
    name: "Profile",
    meta: {
      title: "Profile",
      icon: "guide",
      noCache: true,
      roles: ["admin", "user", "child"]
    },
    hidden: true
  }]
}, {
  path: "/inventory",
  component: _layout.default,
  redirect: "/inventory/index",
  meta: {
    title: "Inventory",
    icon: "component",
    roles: ["admin", "user"]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/inventory/index"));
      });
    },
    name: "bulk-process",
    meta: {
      title: "Bulk process",
      icon: "component",
      noCache: true,
      roles: ["admin", "user"]
    }
  }, {
    path: "report",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/report/inventory"));
      });
    },
    name: "Inventory By Agent",
    meta: {
      title: "Report",
      icon: "component",
      noCache: true,
      roles: ["admin", "user"]
    }
  }]
}, {
  path: "/report",
  component: _layout.default,
  redirect: "/report/sale",
  name: "Report",
  meta: {
    title: "Sale Report",
    icon: "component"
  },
  children: [{
    path: "inventory/agency/detail",
    name: "inventory-agency-detail",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/report/inventory/detail"));
      });
    },
    hidden: true,
    meta: {
      title: "Detail",
      icon: "component",
      noCache: true,
      roles: ["admin", "user", "child"]
    }
  }, {
    path: "sale",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/report/sale"));
      });
    },
    name: "sale-report",
    meta: {
      title: "Detail report",
      icon: "component",
      noCache: true,
      roles: ["admin", "user", "child"]
    }
  }, {
    path: "sale/chain",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/report/sale/chain"));
      });
    },
    name: "sale-chain-report",
    meta: {
      title: "Chain report",
      icon: "component",
      noCache: true,
      roles: ["admin", "user", "child"]
    }
  }, {
    path: "sale/import/history",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/report/sale/import-history"));
      });
    },
    name: "sale-import-history",
    meta: {
      title: "Import sale history",
      icon: "component",
      noCache: true,
      roles: ["admin", "user", "child"]
    },
    hidden: true
  }]
}, {
  path: "/order",
  component: _layout.default,
  redirect: "/order/index",
  name: "Order",
  meta: {
    title: "Order",
    icon: "box",
    roles: ["admin", "onlyLV1"]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/orderListPage"));
      });
    },
    name: "Order History",
    meta: {
      title: "Order History",
      icon: "box",
      noCache: true,
      roles: ["admin", "user"]
    }
  }, {
    path: "create",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/orderFormPage"));
      });
    },
    name: "New Order Request",
    meta: {
      title: "New Order Request",
      icon: "box",
      noCache: true,
      roles: ["admin", "user"]
    }
  }]
}, {
  path: "/agency",
  component: _layout.default,
  redirect: "/agency/index",
  name: "Acengy-layout",
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/agency/index"));
      });
    },
    name: "Agency",
    meta: {
      title: "Agency",
      icon: "peoples",
      noCache: true,
      roles: ["admin"]
    }
  }]
}, {
  path: "/device",
  component: _layout.default,
  redirect: "/device/index",
  name: "device-layout",
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/device/index"));
      });
    },
    name: "Device",
    meta: {
      title: "Device",
      icon: "component",
      noCache: true,
      roles: ["admin"]
    }
  }]
}, {
  path: "/warehouse",
  component: _layout.default,
  redirect: "/warehouse/index",
  name: "warehose-layout",
  meta: {
    title: "Warehouse",
    icon: "component",
    roles: ["admin"]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/product/index"));
      });
    },
    name: "Warehouse",
    meta: {
      title: "Bulk process",
      icon: "component",
      noCache: true,
      roles: ["admin"]
    }
  }, {
    path: "list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/product/imei"));
      });
    },
    name: "ManageWarehouse",
    meta: {
      title: "Manage IMEI",
      icon: "component",
      noCache: true,
      roles: ["admin"]
    }
  }, {
    path: "remove",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/product/remove"));
      });
    },
    name: "RemoveImei",
    meta: {
      title: "Remove IMEI",
      icon: "component",
      noCache: true,
      roles: ["admin"]
    }
  }]
}, {
  path: "/rma",
  component: _layout.default,
  redirect: "/rma/receive",
  meta: {
    title: "RMA",
    icon: "component",
    roles: ["admin", "user"]
  },
  children: [{
    path: "receive",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/receive-imei/"));
      });
    },
    name: "rma-receive",
    meta: {
      title: "RMA Receive",
      icon: "component",
      noCache: true,
      roles: ["admin"]
    }
  }, {
    path: "remove",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/request-imei/"));
      });
    },
    name: "rma-remove",
    meta: {
      title: "RMA Request",
      icon: "component",
      noCache: true,
      roles: ["admin", "user"]
    }
  }, {
    path: "number",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/rma-number"));
      });
    },
    name: "rma-number",
    meta: {
      title: "RMA Number",
      icon: "component",
      noCache: true,
      roles: ["admin"]
    }
  }]
}, {
  path: "/work-submit",
  component: _layout.default,
  redirect: "/work-submit/index",
  name: "work-submit",
  meta: {
    title: "Work calendar",
    icon: "peoples"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/work-submit/index"));
      });
    },
    name: "work-submit-index",
    meta: {
      title: "Time record",
      noCache: true,
      roles: ["child"]
    }
  }, {
    path: "history",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/work-submit/history"));
      });
    },
    name: "work-submit-history",
    meta: {
      title: "History time record",
      noCache: true,
      roles: ["admin", "user"]
    }
  }, {
    path: "calendar",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/work-calendar/index"));
      });
    },
    name: "work-calendar-index",
    meta: {
      title: "Weekly schedule",
      noCache: true,
      roles: ["admin", "user"]
    }
  }]
}, {
  path: process.env.VUE_APP_LEAD_URL + "/admin",
  meta: {
    title: "Contact form",
    icon: "component",
    roles: ["admin"]
  }
}, {
  path: "*",
  redirect: "/404",
  hidden: true
}];
exports.constantRoutes = constantRoutes;
var asyncRoutes = [];
exports.asyncRoutes = asyncRoutes;

var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    mode: "history",
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

var _default = router;
exports.default = _default;