"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLocation = void 0;

require("core-js/modules/es.object.to-string.js");

var getLocation = function getLocation() {
  return new Promise(function (resolve, reject) {
    navigator.geolocation.getCurrentPosition(function (position) {
      resolve({
        lat: position.coords.latitude,
        long: position.coords.longitude
      });
    }, function (err) {
      console.error('err get location', err);
      resolve(false);
    }, {
      maximumAge: 10000,
      enableHighAccuracy: true
    });
  });
};

exports.getLocation = getLocation;