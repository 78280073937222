"use strict";

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/Agency/index.vue"));

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["onClickOpen", "data", "loading"],
  components: {
    Agency: _index.default
  },
  data: function data() {
    return {
      dateRange: [(0, _moment.default)().subtract(7, "day").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")]
    };
  },
  methods: {
    doSearch: function doSearch() {
      var dateFormat = "YYYY-MM-DD HH:mm:ss";
      this.agency = this.$refs["agency"].getData();
      var userLevelIds = {
        level1Id: this.agency["lv1"] == "All" ? null : this.agency["lv1"],
        level2Id: this.agency["lv2"] == "All" ? null : this.agency["lv2"],
        level3Id: this.agency["lv3"] == "All" ? null : this.agency["lv3"],
        level4Id: this.agency["lv4"] == "All" ? null : this.agency["lv4"]
      };
      debugger;
      var from = (0, _moment.default)(this.dateRange[0]).startOf("day").format(dateFormat);
      var to = (0, _moment.default)(this.dateRange[1]).endOf("day").format(dateFormat);
      this.$emit("query", {
        userLevelIds: userLevelIds,
        from: from,
        to: to
      });
    }
  }
};
exports.default = _default;