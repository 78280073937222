"use strict";

var _interopRequireWildcard = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/objectSpread2.js"));

require("core-js/modules/es.error.cause.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/web.dom-collections.for-each.js");

var _date = require("@/utils/date");

var _validate = require("@/utils/validate");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _orderType = require("@/types/orderType");

var order = _interopRequireWildcard(require("@/api/order"));

var _bodyParser = require("body-parser");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    var validateEmail = function validateEmail(rule, value, callback) {
      if (!value) {
        callback(new Error("Email is required"));
      } else if (!(0, _validate.validEmail)(value)) {
        callback(new Error("Email is invalid"));
      } else {
        callback();
      }
    };

    return {
      orderType: _orderType.orderType,
      dialogLoading: false,
      objLevelName: {
        lv1: "Master Agent",
        lv2: "Distributor",
        lv3: "Retailer",
        lv4: "Employee"
      },
      defaultProps: {
        children: "children",
        label: "label"
      },
      listLoading: false,
      total: 0,
      loadingButton: false,
      listQuery: {
        page: 1,
        limit: 10,
        search: ""
      },
      arrData: [],
      dialogVisible: false,
      tempData: {
        name: "",
        email: "",
        phone: "",
        address: "",
        type: "",
        amount: "",
        note: ""
      },
      agencyLevel: [],
      arrUser: [],
      dialogStatus: "",
      rootTree: [],
      rules: {
        name: {
          required: true,
          message: "Name is required",
          trigger: "submit"
        },
        phone: {
          required: true,
          message: "Phone is required",
          trigger: "submit"
        },
        address: {
          required: true,
          message: "Address is required",
          trigger: "submit"
        },
        type: {
          required: true,
          message: "Type is required",
          trigger: "submit"
        },
        amount: {
          required: true,
          message: "Amount is required",
          trigger: "submit"
        },
        city: {
          required: true,
          message: "City is required",
          trigger: "submit"
        },
        state: {
          required: true,
          message: "State is required",
          trigger: "submit"
        },
        zipCode: {
          required: true,
          message: "Zip code is required",
          trigger: "submit"
        },
        companyName: {
          required: true,
          message: "Company name is required",
          trigger: "submit"
        },
        email: {
          required: true,
          trigger: "submit",
          validator: validateEmail
        }
      }
    };
  },
  created: function created() {},
  methods: {
    handleDeny: function handleDeny(userId) {
      var _this = this;

      this.$confirm("This order will be denied. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(function () {
        user.unBlockUser(userId).then(function () {
          _this.arrData.forEach(function (element) {
            if (element.id == userId) {
              element.isActive = 1;
            }
          });

          _this.$message({
            type: "success",
            message: "Deny order successfully"
          });
        });
      });
    },
    handleApprove: function handleApprove(userId) {
      var _this2 = this;

      this.$confirm("This order will be approved. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(function () {
        user.blockUser(userId).then(function () {
          _this2.arrData.forEach(function (element) {
            if (element.id == userId) {
              element.isActive = 0;
            }
          });

          _this2.$message({
            type: "success",
            message: "Approve order successfully"
          });
        });
      });
    },
    resetTempData: function resetTempData() {
      this.tempData = {
        name: "",
        phone: "",
        address: "",
        email: "",
        type: "",
        amount: "",
        city: "",
        state: "",
        zipCode: "",
        companyName: ""
      };
    },
    getData: function getData(row) {
      var _this3 = this;

      this.dialogVisible = true;
      this.$nextTick(function () {
        _this3.$refs["dataForm"].clearValidate();
      });
      this.dialogLoading = true;
      user.getInfoById(row.id).then(function (res) {
        _this3.tempData = res.data;
      }).finally(function () {
        _this3.dialogLoading = false;
      });
    },
    editData: function editData() {
      var _this4 = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          _this4.loadingButton = true;
          var data = _this4.tempData;
          var userId = _this4.tempData.id;
          user.update(userId, data).then(function (res) {
            _this4.$message({
              message: "Edit successfully!",
              type: "success"
            });
          }).finally(function () {
            _this4.loadingButton = false;
          });
        }
      });
    },
    createData: function createData() {
      var _this5 = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          _this5.loadingButton = true;
          order.create({
            order: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this5.tempData), {}, {
              status: _orderType.orderStatus.New
            })
          }).then(function (res) {
            _this5.$message({
              message: "Create successfully!",
              type: "success"
            });

            _this5.resetTempData();
          }).finally(function () {
            _this5.loadingButton = false;
          });
        }
      });
    },
    handleAdd: function handleAdd() {
      var _this6 = this;

      this.dialogStatus = "add";
      this.dialogVisible = true;
      this.avatar = "";
      this.workdate = [];
      this.resetTempData(); // this.tempData.password = randomString(6);

      this.$nextTick(function () {
        _this6.$refs["dataForm"].clearValidate();
      });
      this.getAgencyLevel();
    },
    handleTitleDialog: function handleTitleDialog() {
      if (this.dialogStatus == "edit") {
        return "Info order";
      }

      return "Add order";
    },
    formatDate: function formatDate(timestamp) {
      return (0, _date.formatDate)(timestamp);
    }
  }
};
exports.default = _default;