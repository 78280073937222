var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.url
    ? _c("iframe", {
        staticStyle: { "min-height": "calc(100vh - 100px)", width: "100%" },
        attrs: { src: _vm.url, frameborder: "0" },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }