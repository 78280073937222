"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ImeiRequestStatus = void 0;
var ImeiRequestStatus = {
  valid: 'VALID',
  invalid: 'IN_VALID',
  expired: 'EXPIRED'
};
exports.ImeiRequestStatus = ImeiRequestStatus;