"use strict";

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createProduct = createProduct;
exports.getAllInventory = getAllInventory;
exports.getHistory = getHistory;
exports.getImeis = getImeis;
exports.getReportImeisRemoved = getReportImeisRemoved;
exports.removeImei = removeImei;

var _request = _interopRequireDefault(require("@/utils/request"));

function createProduct(data) {
  return (0, _request.default)({
    url: '/product/add',
    method: 'post',
    data: data
  });
}

function getAllInventory(query) {
  return (0, _request.default)({
    url: '/product/inventory/all',
    method: 'get',
    params: query
  });
}

function getReportImeisRemoved() {
  return (0, _request.default)({
    url: '/product/report/remove'
  });
}

function getImeis(listQuery) {
  return (0, _request.default)({
    url: '/product/getImeis',
    method: 'get',
    params: listQuery
  });
}

function removeImei(data) {
  return (0, _request.default)({
    url: '/product/removeImei',
    method: 'post',
    data: data
  });
}

function getHistory(inventoryId) {
  return (0, _request.default)({
    url: "/product/inventory/history/".concat(inventoryId),
    method: 'get'
  });
}