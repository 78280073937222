"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      visible: false,
      imeis: []
    };
  },
  methods: {
    handleOpen: function handleOpen(imeis) {
      this.imeis = imeis;
      this.visible = true;
    },
    handleConfirm: function handleConfirm() {
      this.$emit("ok");
    }
  }
};
exports.default = _default;