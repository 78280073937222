"use strict";

var _interopRequireDefault = require("C:/Projects/008-QLK_FE/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rmaNumberApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var rmaNumberApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/rma-number',
      params: params
    });
  },
  findRemovedImei: function findRemovedImei(params) {
    return (0, _request.default)({
      url: '/rma-number/removed-imeis',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/rma-number',
      method: 'post',
      data: data
    });
  }
};
exports.rmaNumberApi = rmaNumberApi;