var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.dialogLoading,
            expression: "dialogLoading",
          },
        ],
      },
      [
        _c("h2", [_vm._v("New Order Request")]),
        _c(
          "el-form",
          {
            ref: "dataForm",
            attrs: {
              rules: _vm.rules,
              model: _vm.tempData,
              "label-position": "left",
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12, xs: 24 } },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Company name",
                                  prop: "companyName",
                                },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.tempData.companyName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempData, "companyName", $$v)
                                    },
                                    expression: "tempData.companyName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Name of Recipent",
                                  prop: "name",
                                },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.tempData.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempData, "name", $$v)
                                    },
                                    expression: "tempData.name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Phone", prop: "phone" } },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.tempData.phone,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempData, "phone", $$v)
                                    },
                                    expression: "tempData.phone",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Email", prop: "email" } },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.tempData.email,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempData, "email", $$v)
                                    },
                                    expression: "tempData.email",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Address", prop: "address" } },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.tempData.address,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempData, "address", $$v)
                                    },
                                    expression: "tempData.address",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "City", prop: "city" } },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.tempData.city,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempData, "city", $$v)
                                    },
                                    expression: "tempData.city",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "State", prop: "state" } },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.tempData.state,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempData, "state", $$v)
                                    },
                                    expression: "tempData.state",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Zip code", prop: "zipCode" } },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.tempData.zipCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempData, "zipCode", $$v)
                                    },
                                    expression: "tempData.zipCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "ETC Name", prop: "type" } },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.tempData.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempData, "type", $$v)
                                    },
                                    expression: "tempData.type",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Order quantity",
                                  prop: "amount",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { type: "number" },
                                  model: {
                                    value: _vm.tempData.amount,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempData, "amount", $$v)
                                    },
                                    expression: "tempData.amount",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Note", prop: "note" } },
                              [
                                _c("el-input", {
                                  attrs: { type: "textarea", rows: 3 },
                                  model: {
                                    value: _vm.tempData.note,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempData, "note", $$v)
                                    },
                                    expression: "tempData.note",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "span",
      { staticClass: "dialog-footer" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", loading: _vm.loadingButton },
            on: {
              click: function ($event) {
                _vm.dialogStatus == "edit" ? _vm.editData() : _vm.createData()
              },
            },
          },
          [_vm._v("Confirm")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }